import React from 'react';
import './Loader.css'; // You can define your own styles for the loader

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p>Loading..</p>
    </div>
  );
};

export default Loader;
