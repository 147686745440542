import React, { useEffect } from 'react';
import Footer from '../../shared/Footer';
import Header from '../../shared/Header';
import AnalysisResult from './component/AnalysisResult';
import { useNavigate } from 'react-router';
import { isLoggedIn } from '../../../utils/util';

export default function AnayzerResult() {

    
    const navigate=useNavigate()
    useEffect(() => {
        if (!isLoggedIn()) {
          navigate('/')
        }
      }, [])


    return (
        <div>
        <Header addStyle="fixed top-0 z-20" />
        <AnalysisResult />
        <Footer/>
        </div>
        )
}
