import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChangePassword from "../../../shared/ChangePasswordModal";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import './UserSection.css'
import showToast from "../../../../utils/Toast";

const UserSection = ({ }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showLinksSub, setShowLinksSub] = useState(false)
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const base_url = process.env.REACT_APP_BACKEND_IP;
    const base_assets_url = process.env.REACT_APP_BACKEND_IP_ASSETS;
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem('password');
        showToast({ message: 'Logged Out!' });
        navigate("/");
        setShowMenu(false);
    };

    const userData = { ...JSON.parse(localStorage.getItem("user")) };
    const handleResDownload = (e) => {
        showToast({message:<p className='text-sm'>Downloading {e.target.innerHTML}</p>})


    }
    const Popup = () => {
        return (
            <div className="popup-container">

                <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                >

                    <Link
                        to={`/`}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        role="menuitem"
                    >
                        Home
                    </Link>

                    <Link
                        to={`/allprojects`}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        role="menuitem"
                    >
                        Project List
                    </Link>

                    <Link
                        to={`/profile`}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        role="menuitem"
                    >
                        Profile
                    </Link>

                    <button
                        onClick={() => {
                            setShowMenu(false);
                            setShowPasswordModal(true);
                            setShowLinksSub(false)
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        role="menuitem"
                    >
                        Change Password
                    </button>

                    {userData.userType === 0 && (
                        <Link
                            to="/admin"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                            role="menuitem"
                        >
                            Admin Dashboard
                        </Link>
                    )}

                    {/* onClick={(e) => {
                                    e.stopPropagation()
                                    setShowLinksSub(false)
                                }}
                                className="hover:bg-gray-100 py-1" */}
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowLinksSub(!showLinksSub)
                        }}
                        className="relative block px-4 py-2 text-sm text-gray-700  hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                        role="menuitem"
                    > Useful Links
                    </button>
                    {showLinksSub && showMenu && <div className="bg-[#ffffff] rounded-md w-[9.5rem] absolute -left-40 top-36 text-start text-sm shadow-md  p-1">
                        <ul className=''>
                            <li onClick={(e) => {
                                e.stopPropagation()
                                setShowLinksSub(false)
                            }}
                                className="hover:bg-gray-100 py-1 hover:border-black border-b-2 hover:cursor-pointer  pl-2">

                                <a onClick={(e)=>{
                                    e.stopPropagation()
                                        handleResDownload(e)
                                }} href={`${base_assets_url}/assets/resources/plis_user_manual.pdf`}>PLIS User Manual
                                </a>
                            </li>

                            <li onClick={(e) => {
                                e.stopPropagation()
                                setShowLinksSub(false)
                            }}
                                className="hover:bg-gray-100 py-1 hover:border-black border-b-2 hover:cursor-pointer  pl-2">
                                <a onClick={(e)=>{
                                        handleResDownload(e)
                                }} href={`${base_assets_url}/assets/resources/ccm_report.pdf`}>CCM Report
                                </a></li>


                            <li onClick={(e) => {
                                e.stopPropagation()
                                setShowLinksSub(false)
                            }}
                                className="hover:bg-gray-100 py-1 hover:border-black border-b-2 hover:cursor-pointer  pl-2">
                                <a onClick={(e)=>{
                                        handleResDownload(e)
                                }} href={`${base_assets_url}/assets/resources/Climate_Check_Method.pdf`}>Climate Check Method
                                </a>
                            </li>

                            <li onClick={(e) => {
                                e.stopPropagation()
                                setShowLinksSub(false)
                            }}
                                className="hover:bg-gray-100 py-1 hover:border-black border-b-2 hover:cursor-pointer  pl-2">
                                <a onClick={(e)=>{
                                        handleResDownload(e)
                                }} href={`${base_assets_url}/assets/resources/Climate_change_policy.pdf`}>Climate Change Policy
                                </a></li>
                        </ul>
                    </div>}
                    <button
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        role="menuitem"
                    >
                        Sign out
                    </button>
                </div>





            </div>
        );
    };

    const userSectionRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (userSectionRef.current && !userSectionRef.current.contains(event.target)) {
                setShowMenu(false);
                setShowLinksSub(false)
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <nav className="bg-[#cecece9c] bg-opacity-70 rounded-md w-40 py-2 hover:cursor-pointer" ref={userSectionRef}>
            <div className="mx-auto px-2">
                <div className="flex items-center">
                    <div className="relative">
                        <button className="flex text-sm text-black focus:outline-none" onClick={() => {
                            setShowMenu(!showMenu)
                            if (showMenu) {
                                setShowLinksSub(false)
                            }
                        }}>
                            {Object.keys(userData).length ? (
                                <>
                                    {userData.profileImg ? (
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={`${base_assets_url}/assets/profileimg/${userData.profileImg}`}
                                            alt={userData.userDept}
                                        />
                                    ) : (
                                        <AccountCircleIcon fontSize="large" className="my-auto" />
                                    )}
                                    <div className="ml-3 text-start flex-col my-auto hidden md:flex">
                                        <span className="text-xs">{userData.userName/* .slice(0,9) */}</span>
                                        <span className="text-[.6rem] leading-[.5rem]">{`${userData.userType == 0 ? 'Admin' : "User"}`}</span>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className="ml-4 my-auto" onClick={() => {
                                setShowMenu(!showMenu)
                                if (showMenu) {
                                    setShowLinksSub(false)
                                }
                            }} >
                                {showMenu ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                            </div>
                        </button>
                        {showMenu && <Popup className='scale-y-100' />}
                    </div>
                </div>
            </div>
            {showPasswordModal && <ChangePassword setOpen={setShowPasswordModal} onClose={() => setShowPasswordModal(false)} />}
        </nav>
    );
};

export default UserSection;
