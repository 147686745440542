import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import showToast from "../../utils/Toast";

const ChangePasswordModal = ({ data, onClose }) => {

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("")
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const base_url = process.env.REACT_APP_BACKEND_IP
    const userData = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()


    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation()
        setError(null);

        // Check that the new password and confirm new password match
        if (newPassword !== confirmNewPassword) {
            setError("New password and confirm new password do not match.");
            return;
        }
        if (data) {
            axios.put(`${base_url}/admin/resetuserpassword`, {
                "password": newPassword,
                "userid": data.id
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
                .then(res => {
                    localStorage.setItem("password", newPassword);
                    setError("")
                    setSuccess(true);
                    onClose()
                    setTimeout(() => {
                        navigate('/admin')
                    }, 300);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    setSuccess(false)
                    console.error(e)
                    setError(e.message || e.code)
                })
        }
        else {

            axios.post(`${base_url}/auth/login`, { email: userData.userEmail, password:currentPassword })
                .then(response => {

                    axios.put(`${base_url}/auth/resetpassword`, { password: newPassword }, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    })
                        .then(res => {

                            localStorage.setItem("password", newPassword);
                            setError("")
                            setSuccess(true);
                            onClose()
                            showToast({message:'Password Changed Successfully!'})
                            setTimeout(() => {
                                navigate('/allprojects')
                            }, 300);

                        })
                        .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }

                            setSuccess(false)
                            console.error(e)
                            setError(e.message || e.code)
                        })



                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    showToast({message:'Current password Doesn\'t match!'})
                    setError('Current password Doesn\'t match!')
                })

        }

        // Save the new password to local storage

    };

    return (
        <div className="fixed inset-0 z-10 h-full bg-gray-50
         bg-opacity-50 flex items-center justify-center">
            <div className="max-w-md w-full ">
                {/* Content */}
                <div className=" flex flex-col w-full bg-white rounded-lg shadow-lg">
                    <div className="flex items-center justify-between px-6 py-4 bg-gray-900 rounded-t-lg">
                        <h2 className="text-lg text-md text-white">Change Password</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-300 hover:text-white focus:outline-none"
                        >
                            <svg
                                className="w-6 h-6 fill-current"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Close</title>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10 8.586L16.95 1.636a1 1 0 011.414 1.414L11.414 10l7.95 7.95a1 1 0 11-1.414 1.414L10 11.414l-7.95 7.95a1 1 0 11-1.414-1.414L8.586 10 1.636 2.05A1 1 0 012.05.636L10 8.586z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="px-6 py-4">

                        <>

                            <form onSubmit={handleSubmit}>

                                {!data && <div className="mb-4">
                                    <label
                                        className="block mb-2 text-md text-gray-700"
                                        htmlFor="currentPassword"
                                    >
                                        Current Password
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        id="currentpassword"
                                        type="password"
                                        value={currentPassword}

                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                </div>}
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 text-md text-gray-700"
                                        htmlFor="newPassword"
                                    >
                                        New Password
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 leading-tigh
                                         text-gray-700 border rounded 
                                         shadow  focus:outline-none
                                          focus:shadow-outline"
                                        id="newPassword"
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block mb-2 text-md text-gray-700"
                                        htmlFor="confirmNewPassword"
                                    >
                                        Confirm New Password
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        id="confirmNewPassword"
                                        type="password"
                                        value={confirmNewPassword}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="mt-8">
                                    <button
                                        type="submit"
                                        className="inline-block w-full px-4 py-2 text-sm text-md text-white bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                                    >
                                        Change Password
                                    </button>
                                </div>

                            </form>
                        </>
                        {success && (
                            <p className="text-green-500">
                                Password changed successfully. You will now be redirected to
                                the {data ? 'Admin' : 'projects '} page.</p>)
                        }
                        {error && <p className="mb-4 text-red-500">{error}</p>}


                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChangePasswordModal