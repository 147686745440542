import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const RiverLayerSrc = new TileWMS({
    params: { LAYERS: 'river_bd', TILED: true },
    url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
    serverType: 'geoserver',
    style: {
        format: 'geojson',
    },
    crossOrigin: 'anonymous',
});

const RoadLayerSrc = new TileWMS({
    url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
    params: { LAYERS: 'roads_line', TILED: true },
    serverType: 'geoserver',
    crossOrigin: 'anonymous',
});

const HatBazarSrc = new TileWMS({
    params: { LAYERS: 'hatbazar_bd', TILED: true },
    url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
    serverType: 'geoserver',
    crossOrigin: 'anonymous',
    ratio: 1,
});

const ClimateStressSrc = new TileWMS({
    params: { LAYERS: 'climatestress_area_bd', TILED: true },
    url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
    serverType: 'geoserver',
    crossOrigin: 'anonymous',
    ratio: 1,
});

const ClimateStressPointSrc = new TileWMS({
    params: { LAYERS: 'climatestresspoint_bd', TILED: true },
    url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
    serverType: 'geoserver',
    crossOrigin: 'anonymous',
    ratio: 1,
});


const RiverLayer = new TileLayer({
    title: 'River',
    name: 'river_bd',
    source: RiverLayerSrc,
    visible: false,
});


const RoadLayer = new TileLayer({
    title: 'Road',
    name: 'roads_line',
    source: RoadLayerSrc,
    // minZoom: '15',
    visible: false,
});
const HatBazarLayer = new TileLayer({
    title: 'HatBazar',
    name: 'hatbazar_bd',
    source: HatBazarSrc,
    opacity: 0.5,
    //  maxZoom: '15',
    visible: false,
});
const WaterBodyLayer = new TileLayer({
    title: 'Waterbody',
    name: 'waterbodies_bd',
    source: new TileWMS({
        params: { LAYERS: 'waterbodies_bd', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.5,
    //  maxZoom: '15',
    visible: false,
});
const SoilMoistureLayer = new TileLayer({
    title: 'Soil Moisture',
    name: 'soil_moisture_bd',
    source: new TileWMS({
        params: { LAYERS: 'soil_moisture_bd', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.5,
    //  maxZoom: '15',
    visible: false,
});

const SoilSalinityLayer = new TileLayer({
    title: 'Soil Salinity',
    name: 'salinity_soil_bd',
    source: new TileWMS({
        params: { LAYERS: 'salinity_soil_bd', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.5,
    //  maxZoom: '15',
    visible: false,
});

const DeltaHotspotLayer = new TileLayer({
    title: 'Delta Hotspot',
    name: 'delta_hotspot_zone',
    source: new TileWMS({
        params: { LAYERS: 'delta_hotspot_zone', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.5,
    //  maxZoom: '15',
    visible: false,
});
const Flood10YearsReturnPeriodLayer = new TileLayer({
    title: 'Flood 10 Years Return Period',
    name: 'flood10YearsReturn_period',
    source: new TileWMS({
        params: { LAYERS: 'flood10YearsReturn_period', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const Flood25YearsReturnPeriodLayer = new TileLayer({
    title: 'Flood 25 Years Return Period',
    name: 'flood25YearsReturn_period',
    source: new TileWMS({
        params: { LAYERS: 'flood25YearsReturn_period', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});
const Flood100YearsReturnPeriodLayer = new TileLayer({
    title: 'Flood 100 Years Return Period',
    name: 'flood100YearsReturn_period',
    source: new TileWMS({
        params: { LAYERS: 'flood100YearsReturn_period', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const ClimateStressLayer = new TileLayer({
    title: 'Climate Stress Areas',
    name: 'climatestress_area_bd',
    source: ClimateStressSrc,
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const ClimateStressPointLayer = new TileLayer({
    title: 'Climate Stress Point',
    name: 'climatestresspoint_bd',
    source: ClimateStressPointSrc,
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});


const LandUseBdLayer = new TileLayer({
    title: 'Landuse BD',
    name: 'landuse_bd',
    source: new TileWMS({
        params: { LAYERS: 'landuse_bd', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const StructureBdLayer = new TileLayer({
    title: 'Structure BD',
    name: 'structure_bd',
    source: new TileWMS({
        params: { LAYERS: 'structure_bd', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const ECABdLayer = new TileLayer({
    title: 'Ecological Critical Area',
    name: 'ecological_critical_area',
    source: new TileWMS({
        params: { LAYERS: 'ecological_critical_area', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});


const BrickFieldLayer = new TileLayer({
    title: 'Brick Field BD',
    name: 'brickfield_bd',
    source: new TileWMS({
        params: { LAYERS: 'brickfield_bd', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const DetailAreaPlan = new TileLayer({
    title: 'Detail Area Plan',
    name: 'detailareaplan_proposed',
    source: new TileWMS({
        params: { LAYERS: 'detailareaplan_proposed', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});


const Cyclone100YearsReturnPeriodLayer = new TileLayer({
    title: 'Cyclone 100 Years Return Period',
    name: 'cyclone100YearsReturn_period',
    source: new TileWMS({
        params: { LAYERS: 'cyclone100YearsReturn_period', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

const Cyclone25YearsReturnPeriodLayer = new TileLayer({
    title: 'Cyclone 25 Years Return Period',
    name: 'cyclone25YearsReturn_period',
    source: new TileWMS({
        params: { LAYERS: 'cyclone25YearsReturn_period', TILED: true },
        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
        ratio: 1,
    }),
    opacity: 0.7,
    //  maxZoom: '15',
    visible: false,
});

export const getTitleLayers = (title) => {

    switch (title) {
        case 'Flood 10 Years Return Period': // Title Name 
            return Flood10YearsReturnPeriodLayer; // Declared Layer Name 
        case 'Flood 25 Years Return Period':
            return Flood25YearsReturnPeriodLayer;
        case 'Flood 100 Years Return Period':
            return Flood100YearsReturnPeriodLayer;
        case 'WaterBody':
            return WaterBodyLayer;
        case 'Road':
            return RoadLayer;
        case 'HatBazar':
            return HatBazarLayer;
        case 'Soil Moisture':
            return SoilMoistureLayer;
        case 'Soil Salinity':
            return SoilSalinityLayer;
        case 'Delta Hotspot':
            return DeltaHotspotLayer;
        case 'River':
            return RiverLayer;
        case 'Climate Stress Areas':
            return ClimateStressLayer;
        case 'Climate Stress Point':
            return ClimateStressPointLayer;
        case 'Landuse BD':
            return LandUseBdLayer;
        case 'Structure BD':
            return StructureBdLayer;
        case 'Ecological Critical Area':
            return ECABdLayer;
        case 'Brick Field BD':
            return BrickFieldLayer;
        case 'Detail Area Plan':
            return DetailAreaPlan;
        case 'Cyclone 100 Years Return Period':
            return Cyclone100YearsReturnPeriodLayer;
        case 'Cyclone 25 Years Return Period':
            return Cyclone25YearsReturnPeriodLayer;

    }
}

export const allLayers = [
    DeltaHotspotLayer,
    RoadLayer,
    RiverLayer,
    HatBazarLayer,
    Flood10YearsReturnPeriodLayer,
    Flood25YearsReturnPeriodLayer,
    Flood100YearsReturnPeriodLayer,
    WaterBodyLayer,
    SoilMoistureLayer,
    SoilSalinityLayer,
    ClimateStressLayer,
    ClimateStressPointLayer,
    LandUseBdLayer,
    StructureBdLayer,
    ECABdLayer,
    BrickFieldLayer,
    DetailAreaPlan,
    Cyclone100YearsReturnPeriodLayer,
    Cyclone25YearsReturnPeriodLayer
]
