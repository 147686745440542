import React, { useEffect, useState } from 'react'
import CreateNewUser from '../CreateUser';
import axios from 'axios';

const ProfileEditModal = ({ data, onClose }) => {
    const { ID, ...newdata } = data

    const [editData, setEditData] = useState(newdata)
    const [agencyList, setAgencyList] = useState()
    const [minsitryList, setMinsitryList] = useState()
    const [sectorList, setSectorList] = useState()
    const [subSectorList, setSubSectorList] = useState()
    const [divisionList, setDivisionList] = useState([])

    const base_url = process.env.REACT_APP_BACKEND_IP

    const handleUpdate = (e) => {
        e.preventDefault()
    
    }


    const handleChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value })

    }



    useEffect(() => {
        /* MINISTRY */
        if (editData['Agency Name']) {
            axios.get(`${base_url}/options/ministry`)
                .then(res => {
                    setMinsitryList([{ ministry_name: 'Show All' }, ...res.data.ministryList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
            /* AGENCY */
            axios.get(`${base_url}/options/agency`, {/* ${ministry_id ? `?ministryid=${ministry_id}` : ""} */

            })
                .then(res => {
                    setAgencyList(res.data.agencyList);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })

        }
        else {
            /* DIVISION */
            axios.get(`${base_url}/options/sectordiv`,)
                .then(res => {

                    setDivisionList([{ sector_div_name: 'Show All' }, ...res.data.sectordivList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })

            /* SECTORS */
            axios.get(`${base_url}/options/sectors?divId=${'1'}`,)
                .then(res => {

                    setSectorList([{ sector_name_en: 'Show All' }, ...res.data.sectorList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })

            /* SUB SECTORS */
            axios.get(`${base_url}/options/subSectors`/* ?sector=${'10'}` */)
                .then(res => {
                    setSubSectorList(res.data.subSectorList);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
        }

    }, [])

    useEffect(() => {

    }, [editData.sector_div_id])


    useEffect(() => {

    }, [editData.sector_id])


    useEffect(() => {

    }, [editData.sub_sector_id])



    return (
        <div className="fixed top-0  z-50 w-screen h-full overflow-auto bg-opacity-50 bg-gray-500">
            <div className="relative  top-[20%] p-6  mx-auto my-auto w-[60%]">
                <div className="relative flex flex-col  bg-white rounded-lg shadow-lg">
                    <div className="flex items-center  px-6 py-4 bg-gray-400 rounded-t-lg">
                        <h2 className='mx-auto text-xl font-semibold'>Edit Info</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-300 hover:text-white focus:outline-none"
                        >
                            <svg
                                className="w-6 h-6 fill-current"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Close</title>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10 8.586L16.95 1.636a1 1 0 011.414 1.414L11.414 10l7.95 7.95a1 1 0 11-1.414 1.414L10 11.414l-7.95 7.95a1 1 0 11-1.414-1.414L8.586 10 1.636 2.05A1 1 0 012.05.636L10 8.586z"
                                />
                            </svg>
                        </button>
                    </div>

                    <form onSubmit={handleUpdate} className='mt-2 mb-4 px-8'>
                        <div className='grid grid-cols-2 text-start gap-8 px-2 mb-2' >


                            <div className='emailContainer'>
                                <div className='pl-1 text-sm w-full '>Email</div>
                                <input type='text' onChange={handleChange} name={"User Email"}
                                    className='h-10 border mt-1 rounded px-2 w-full bg-gray-50'
                                    value={editData['User Email']} />
                            </div>


                            {editData['Agency Name']
                                ? (<>

                                    <div className='ministryContainer'>
                                        <div className='pl-1 text-sm w-full '>Ministry</div>
                                        <select
                                            onChange={handleChange}
                                            name={"ministry_id"}
                                            className='h-10 border mt-1 rounded px-2 w-full bg-gray-50'
                                        >

                                            {minsitryList?.map((option, index) => {
                                                return (
                                                    <option className='text-black'
                                                        key={option.ministry_name}
                                                        selected={editData['Ministry Name'] === option.ministry_name}
                                                        value={option.ministry_id || ''}>
                                                        {option.ministry_name}

                                                    </option>
                                                )
                                            })}
                                        </select>


                                    </div>
                                    <div className='agencyContainer'>
                                        <div className='pl-1 text-sm w-full'>{'Agency'}</div>
                                        <select
                                            onChange={handleChange}
                                            name={"agency_id"}
                                            className='h-10 border mt-1 rounded px-2 w-full bg-gray-50'
                                        >
                                            {agencyList?.map((option) => (
                                                <option
                                                    selected={editData['Agency Name'] === option.agency_name}
                                                    key={option.agency_name + "_" + option.agency_id} value={option.agency_id}>
                                                    {option.agency_name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </>) :
                                <>
                                    <div className='sectorDivisionContainer'>
                                        <div className='pl-1 text-sm w-full '>Sector Division</div>

                                        <select
                                            onChange={handleChange} name={"sector_div_id"}
                                            className='h-10 border mt-1 rounded px-2 w-full bg-gray-50'
                                        >

                                            {divisionList?.map((option) => (
                                                <option
                                                    selected={editData['Sector Division Name'] === option.sector_div_name}
                                                    key={option.sector_div_name} value={option.sector_div_id}>
                                                    {option.sector_div_name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div className='sectorContainer'>
                                        <div className='pl-1 text-sm w-full '>Sector</div>

                                        <select
                                            onChange={handleChange} name={"sector_id"}
                                            className='h-10 border mt-1 rounded px-2 w-full bg-gray-50'
                                        >

                                            {sectorList?.map((option) => (
                                                <option
                                                    selected={editData['Sector Name'] === option.sector_name_en}
                                                    key={option.sector_name} value={option.sector_id}>
                                                    {option.sector_name_en}
                                                </option>
                                            ))}
                                        </select>

                                    </div>

                                    <div className='subSectorContainer'>
                                        <div className='pl-1 text-sm w-full '>Sub Sector</div>

                                        <select
                                            onChange={handleChange} name={"sub_sector_id"}
                                            className='h-10 border mt-1 rounded px-2 w-full bg-gray-50'
                                        >
                                            <option value={""}>Select Sub Sector</option>
                                            {subSectorList?.map((option) => (
                                                <option
                                                    selected={editData['Sub Sector Name'] === option.sub_sector_name_en}
                                                    key={option.sub_sector_name_en} value={option.sub_sector_id}>
                                                    {option.sub_sector_name_en}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </>}



                        </div>
                        <div className='w-full flex gap-2 mx-auto justify-center h-8 mt-4'>
                            <button type='submit' className='bg-[#818b8b] hover:bg-[#a2a39e] px-2 rounded-md '>Update</button>
                            <button onClick={onClose} type='button' className='bg-[#818b8b]  hover:bg-[#a2a39e] px-2 rounded-md '>Close</button>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProfileEditModal