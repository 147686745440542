import React, { useEffect, useState } from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import { useNavigate } from 'react-router'
import { Button, Checkbox, Chip, Divider, FormControlLabel, Radio, Typography } from '@mui/material'
import SelectDropDown from '../shared/SelectDropDown'
import TextFieldInput from '../shared/TextFeildInput'
import axios from 'axios'
import { isLoggedIn } from '../../utils/util'
import AddNewUser from './AddNewUser'
import SignUp from './SignUp'
import HomeBg from '../../assets/images/home-bg.jpg'

const CreateNewUser = () => {


    const base_url = process.env.REACT_APP_BACKEND_IP
    const navigate = useNavigate()
    const [values, setValues] = useState({})
    const [error, setError] = useState("")

    const [ministryList, setMinistryList] = useState([])
    const [agencyList, setAgencyList] = useState([])

    const [divisionList, setDivisionList] = useState([])
    const [sectorList, setSectorList] = useState([])
    const [subSectorList, setSubSectorList] = useState([])

    const [checked, setChecked] = useState(false)
    const [success, setSuccess] = useState("")
    const userData = JSON.parse(localStorage.getItem('user'))





    const handleChange = (name, value) => {

        setValues({ ...values, [name]: value })
    }
    const validatEmail = (email) => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        return regex.test(email)
    }
    const validateUserInfo = () => {
        if (!values.first_name) {
            setError("Enter First Name")
            document.getElementById("first_name").focus()
            return false
        }
        else if (!values.last_name) {
            setError("Enter Last Name")
            document.getElementById("last_name").focus()
            return false
        }
        else if (!values.email) {
            setError("Enter Email")
            document.getElementById("email").focus()
            return false
        }
        else if (!validatEmail(values.email)) {
            setError("Enter Valid Email")
            document.getElementById("email").focus()
            return false
        }
        else if (!values.password) {
            setError("Enter Password")
            document.getElementById("password").focus()
            return false
        }
        else if (!values.confirm_password) {
            setError("Confirm Your Password")
            document.getElementById("confirm_password").focus()
            return false
        }
        else if (values.password !== values.confirm_password) {
            setError("Password Doesn\'t Match")
            document.getElementById("confirm_password").focus()
            return false
        }
        setError("")
        return true
    }
    const validateOfficeInfo = () => {

        if (!values.user_type) {
            setError("Sepecify User Type")
            document.getElementById("user_type").focus()
            return false
        }
        else if (values.user_type === 'Planning Commission User') {
            if (!values.ministry) {
                setError("Sepecify Ministry")
                document.getElementById("ministry").focus()
                return false
            }
            else if (!values.agency) {
                setError("Sepecify Agency")
                document.getElementById("agency").focus()
                return false
            }
        }
        else if (values.user_type === 'Agency User') {
            if (!values.division) {
                setError("Sepecify Division")
                document.getElementById("division").focus()
                return false
            }
            else if (!values.sector) {
                setError("Sepecify Sector")
                document.getElementById("sector").focus()
                return false
            }
            else if (!values.sub_sector) {
                setError("Sepecify Sub-Sector")
                document.getElementById("sub_sector").focus()
                return false
            }
        }
        setError("")
        return true
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = {}
        if (/* validateOfficeInfo() && */ validateUserInfo()) {
            if (values.user_type === 'Agency User') {
                formData.user_type = checked ? "0" : "2"
                formData.ministry_id = values.ministry ? ministryList.filter(el => el.ministry_name === values.ministry)[0]?.ministry_id : null;
                formData.agency_id = values.agency ? agencyList.filter(el => el.agency_name === values.agency)[0]?.agency_id : null
                formData.sector_div_id = null
                formData["sector_id"] = null
                formData["sub_sector_id"] = null
            }
            else if (values.user_type === 'Planning Commission User') {
                formData.user_type = checked ? "0" : "1"
                formData.ministry_id = null;
                formData.agency_id = null
                formData.sector_div_id = values.division ? divisionList.filter(el => el.sector_div_name === values.division)[0]?.sector_div_id : null;
                formData["sector_id"] = values.sector ? sectorList.filter(el => el.sector_name_en === values.sector)[0]?.sector_id : null;
                formData["sub_sector_id"] = values.sub_sector ? subSectorList.filter(el => el.sub_sector_name_en === values.sub_sector)[0]?.sub_sector_id : null;
            }
            formData.first_name = values.first_name
            formData.middle_name = values.middle_name ? values.middle_name : null
            formData.last_name = values.last_name
            formData.email = values.email
            formData.password = values.password


            axios.post(`${base_url}${userData && userData.userType == 0 ? '/admin/createuser' : '/auth/signup'}`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
                .then(res => {
                    setSuccess(`User ${formData.first_name + " " + formData.middle_name ? formData.middle_name + " " : "" + formData.last_name} Added Successfully!`)

                    if (res.status == 201) {
                        alert(`User ${formData.first_name + " " + formData.middle_name ? formData.middle_name + " " : "" + formData.last_name} Added Successfully!` +
                            "\n" + "Contact Admin For Approval")
                    }
                    setTimeout(() => {

                        navigate((userData && userData.userType == 0) ? '/admin' : '/')
                    }, 350);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    setError(e.response?.data || e.messege || e.code);
                    if (e.response?.data === 'Email Already Exist') {
                        // document.getElementById("email").style.color= '#ff0000'
                        document.getElementById("email").focus()
                    }
                })
        }

    }

    useEffect(() => {
        if (values.user_type === 'Agency User') {
            axios.get(`${base_url}/options/ministry`)
                .then(res => {
                    setMinistryList([{ ministry_name: 'Show All' }, ...res.data.ministryList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
        }
        else if (values.user_type === 'Planning Commission User') {
            axios.get(`${base_url}/options/sectordiv`)
                .then(res => {
                    setDivisionList([{ sector_div_name: 'Show All' }, ...res.data.sectordivList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
        }

    }, [values.user_type])


    useEffect(() => {
        const divId = divisionList.filter(el => {

            return el.sector_div_name === values.division
        })[0]?.sector_div_id

        axios.get(`${base_url}/options/sectors${divId ? `?divId=${divId}` : ""}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {
                setSectorList([{ sector_name_en: 'Show All' }, ...res.data.sectorList]);
            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })
    }, [values.division])



    useEffect(() => {
        const sectorId = sectorList.filter(el => {
            return el.sector_name_en === values.sector
        })[0]?.sector_div_id

        axios.get(`${base_url}/options/subsectors${sectorId ? `?id=${sectorId}` : ""}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {

                setSubSectorList(res.data.subSectorList);
            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })
    }, [values.sector])



    useEffect(() => {
        const ministry_id = ministryList.filter(el =>
            el.ministry_name === values.ministry)[0]?.ministry_id
        axios.get(`${base_url}/options/agency${ministry_id ? `?ministryid=${ministry_id}` : ""}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {
                setAgencyList(res.data.agencyList);
            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })
    }, [values.ministry])


    return (
        <div className=' h-screen bg-cover' style={{ backgroundImage: `url(${HomeBg})` }}>
            <Header />
            <main className='w-full z-0 lg:w-[60%] mx-auto  px-2 pt-2 pb-16 bg-[#ffffffb4] text-black shadow-md mt-4 rounded-lg'>
                <div className=' text-center font-semibold text-3xl'>
                    {(userData && userData.userType == 0) ? (
                        <>
                            <p>Create New User</p>
                            <AddNewUser />
                        </>) :
                        (<>
                            <p>Register User</p>
                            <SignUp />
                        </>)}
                   
                </div>

            </main>
            <Footer />
        </div>
    )
}

export default CreateNewUser