import AnalizerMap from "../../../Dashboards/Map/component/AnalizerMap";
import Login from "../../Auth/login";
import Landing from '../../../Dashboards/Landings'
import UploadSpatialFile from "../../../UploadSpatialFile";
import AddProject from "../../../AddProject";
import AdminDashboard from '../../../AdminDashboard'
import Profile from "../../../shared/Profile";
import PPSAnalysis from '../../../Dashboards/PPSAnaliser'
import CreateNewUser from "../../../CreateUser";
import PPSLanding from "../../../shared/PPS";
import AllProject from "../../../Dashboards/Landings/AllProjects";
import AnayzerResult from "../../../Dashboards/AlanyzerResult";

const createRoute = (path, Element) => {
    return { path: path, element: Element }
}


export const routes = [
    createRoute('/', <Login />),
    createRoute('/home', <Landing />),
    createRoute('/map', <AnalizerMap />),
    createRoute('/allprojects',<AllProject/> ),
    createRoute('/analyzerresult', <AnayzerResult />),
    createRoute('/upload', <UploadSpatialFile />),
    createRoute('/addProject', <AddProject />),
    createRoute('/admin', <AdminDashboard />),
    createRoute('/profile', <Profile />),
    createRoute('/adduser', <CreateNewUser />),
    createRoute('/register', <CreateNewUser />),
    createRoute('/dppdetails', <PPSLanding />),
    createRoute('/ppsanalysis', <PPSAnalysis />),
]; 