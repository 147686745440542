import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Checkbox, Chip, Divider, FormControlLabel, Typography } from '@mui/material'
import SelectDropDown from '../shared/SelectDropDown'
import TextFieldInput from '../shared/TextFeildInput'
import axios from 'axios'
import showToast from '../../utils/Toast'



const SignUp = () => {


    const base_url = process.env.REACT_APP_BACKEND_IP
    const navigate = useNavigate()
    const [values, setValues] = useState({})
    const [error, setError] = useState("")

    const [ministryList, setMinistryList] = useState([])
    const [agencyList, setAgencyList] = useState([])

    const [divisionList, setDivisionList] = useState([])
    const [sectorList, setSectorList] = useState([])
    const [subSectorList, setSubSectorList] = useState([])

    const [checked, setChecked] = useState(false)
    const [success, setSuccess] = useState("")
    const userData = JSON.parse(localStorage.getItem('user'))


    const handleChange = (name, value) => {

        setValues({ ...values, [name]: value })
    }
    const validatEmail = (email) => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        return regex.test(email)
    }
    const validateUserInfo = () => {
        if (!values.first_name) {
            setError("Enter First Name")
            document.getElementById("first_name").focus()
            return false
        }
        else if (!values.last_name) {
            setError("Enter Last Name")
            document.getElementById("last_name").focus()
            return false
        }
        else if (!values.email) {
            setError("Enter Email")
            document.getElementById("email").focus()
            return false
        }
        else if (!validatEmail(values.email)) {
            setError("Enter Valid Email")
            document.getElementById("email").focus()
            return false
        }
        else if (!values.password) {
            setError("Enter Password")
            document.getElementById("password").focus()
            return false
        }
        else if (!values.confirm_password) {
            setError("Confirm Your Password")
            document.getElementById("confirm_password").focus()
            return false
        }
        else if (values.password !== values.confirm_password) {
            setError("Password Doesn\'t Match")
            document.getElementById("confirm_password").focus()
            return false
        }
        setError("")
        return true
    }
    const validateOfficeInfo = () => {

        if (!values.user_type) {
            setError("Sepecify User Type")
            document.getElementById("user_type").focus()
            return false
        }
        else if (values.user_type === 'Agency User') {

            if (!values.agency) {
                setError("Sepecify Agency")
                document.getElementById("agency").focus()
                return false
            }
        }
        else if (values.user_type === 'Planning Commission User') {
            if (!values.division) {
                setError("Sepecify Division")
                document.getElementById("division").focus()
                return false
            }

        }
        setError("")
        return true
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        const formData = {}
        if (validateOfficeInfo() && validateUserInfo()) {
            if (values.user_type === 'Agency User') {
                formData.user_type = checked ? "0" : "2"
                formData.ministry_id = values.ministry ? ministryList.filter(el => el.ministry_name === values.ministry)[0]?.ministry_id : null;
                formData.agency_id = values.agency ? agencyList.filter(el => el.agency_name === values.agency)[0]?.agency_id : null
                formData.sector_div_id = null

                formData["sector_id"] = null
                formData["sub_sector_id"] = null

            }
            else if (values.user_type === 'Planning Commission User') {
                formData.user_type = checked ? "0" : "1"
                formData.ministry_id = null;
                formData.agency_id = null
                formData.sector_div_id = values.division ? divisionList.filter(el => el.sector_div_name === values.division)[0]?.sector_div_id : null;



                formData["sector_id"] = values.sector ? sectorList.filter(el => el.sector_name_en === values.sector)[0]?.sector_id : null;
                formData["sub_sector_id"] = values.sub_sector ? subSectorList.filter(el => el.sub_sector_name_en === values.sub_sector)[0]?.sub_sector_id : null;
            }
            formData.first_name = values.first_name
            formData.middle_name = values.middle_name ? values.middle_name : null
            formData.last_name = values.last_name
            formData.email = values.email
            formData.password = values.password




            axios.post(`${base_url}${userData && userData.userType == 0 ? '/admin/createuser' : '/auth/signup'}`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
                .then(res => {
                    setSuccess(`User ${formData.first_name + " " + formData.middle_name ? formData.middle_name + " " : "" + formData.last_name} Added Successfully!`)
                    showToast({ message: 'Sign Up Successfull!' })

                    setTimeout(() => {

                        navigate((userData && userData.userType == 0) ? '/admin' : '/')
                    }, 350);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    setError(e.response?.data || e.messege || e.code);
                    if (e.response?.data === 'Email Already Exist') {
                        document.getElementById("email").focus()
                    }
                })

        }


    }

    useEffect(() => {

        if (values.user_type === 'Agency User') {
            axios.get(`${base_url}/options/ministry`)
                .then(res => {
                    setMinistryList([{ ministry_name: 'Show All' }, ...res.data.ministryList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
        }
        else if (values.user_type === 'Planning Commission User') {
            axios.get(`${base_url}/options/sectordiv`)
                .then(res => {
                    setDivisionList([{ sector_div_name: 'Show All' }, ...res.data.sectordivList]);
                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
        }

    }, [values.user_type])




    useEffect(() => {
        const divId = divisionList.filter(el => {

            return el.sector_div_name === values.division
        })[0]?.sector_div_id

        axios.get(`${base_url}/options/sectors${divId ? `?divId=${divId}` : ""}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {
                setSectorList([{ sector_name_en: 'Show All' }, ...res.data.sectorList]);
            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })
    }, [values.division])



    useEffect(() => {
        const sectorId = sectorList.filter(el => {

            return el.sector_name_en === values.sector
        })[0]?.sector_div_id

        axios.get(`${base_url}/options/subsectors${sectorId ? `?id=${sectorId}` : ""}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {

                setSubSectorList(res.data.subSectorList);
            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })
    }, [values.sector])


    useEffect(() => {
        const ministry_id = ministryList.filter(el =>
            el.ministry_name === values.ministry)[0]?.ministry_id
        axios.get(`${base_url}/options/agency${ministry_id ? `?ministryid=${ministry_id}` : ""}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {
                setAgencyList(res.data.agencyList);
            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })
    }, [values.ministry])


    return (
        <div className='form-container mt-4'>
            <form onSubmit={handleSubmit} className=''>
                <Divider sx={{ width: '65%', ml: 'auto', mr: 'auto' }}>
                    <Chip className='font-semibold' label="Office Details" />
                </Divider>
                <div className='grid  lg:grid-cols-2'>
                    <SelectDropDown id={'user_type'} label={"Select User Type"} name={'user_type'}
                        element={["Planning Commission User", "Agency User"]} handler={handleChange} />
                    {values?.user_type === "Agency User" &&
                        <>
                            <SelectDropDown id={'ministry'} label={"Select Ministry / Others"} name={'ministry'}
                                element={ministryList?.map(el => {
                                    return el.ministry_name
                                })} handler={handleChange} />
                            <SelectDropDown id={'agency'} label={"Select Agency"} name={'agency'}
                                element={agencyList?.map(el => {
                                    return el.agency_name
                                })} handler={handleChange} />
                        </>
                    }
                    {values?.user_type === "Planning Commission User" &&
                        <>
                            <SelectDropDown id={'division'} label={"Select Division"} name={'division'}
                                element={divisionList.map(el => {
                                    return el.sector_div_name
                                })} handler={handleChange} />

                        </>}
                </div>


                <div className=' w-full mt-4'>
                    <Divider sx={{ width: '65%', ml: 'auto', mr: 'auto' }}>
                        <Chip className='font-semibold' label="User Details" />
                    </Divider>
                    <div className='grid lg:grid-cols-2 mb-4'>
                        <TextFieldInput id={'first_name'} name={'first_name'} label={"First Name"} inputType='text' setter={handleChange} />
                        <TextFieldInput id={'middle_name'} name={'middle_name'} label={"Middle Name"} inputType='text' setter={handleChange} />
                        <TextFieldInput id={'last_name'} name={'last_name'} label={"Last Name"} inputType='text' setter={handleChange} />
                        <TextFieldInput id={'email'} name={'email'} label={"Email"} inputType='text' setter={handleChange} />
                        <TextFieldInput id={'password'} name={'password'} label={"Password"} inputType='password' setter={handleChange} />
                        <TextFieldInput id={'confirm_password'} name={'confirm_password'} label={"Confirm Password"}
                            inputType='password' handler={handleChange} setter={handleChange} />
                    </div>
                </div>

                {(error && error.length) &&
                    <p className='text-red-500 py-2 px-2 text-sm'>{error}</p>
                }
                {success && success.length &&
                    (<p className='py-2 px-2 text-sm'>{success}</p>)}

                {(userData && userData.userType == 0) && (<div>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checked} onChange={(e) => {
                                setChecked(!checked)
                                setValues({ ...values, [e.target.name]: e.target.value })
                            }} name="isAdmin" />
                        }
                        label={checked ? "Revoke Admin Role?" : "Grant Admin Role?"}
                    />
                </div>)}
                <Button variant='contained' type='submit' >Submit User</Button>
                <Divider sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">Already have an Account?
                            <span onClick={(e) => {
                                navigate('/')
                            }} className='italic bg-slate-400 rounded-md ml-1 px-1 hover:cursor-pointer hover:bg-slate-300'>Sign In</span></Typography>
                    </Divider>
            </form>

        </div>
    )
}

export default SignUp