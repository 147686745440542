
import { AppContext } from '../shared/Context'
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const App = () => {

  const [projectData, setProjectData] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [basicProjectData, setBasicProjectData] = useState(null);
  const [maps, setMaps] = useState({})
  const [popupData, setPopupData] = useState({})
  const [ccmq, setCcmq] = useState({})
  const [userData, setUserData] = useState({})
  const [selectedLayers, setSelectedLayers] = useState()

  const data = {

    projectData, setProjectData,
    selectedLayer, setSelectedLayer,
    analysisResult, setAnalysisResult,
    basicProjectData, setBasicProjectData,
    maps, setMaps,
    selectedLayers, setSelectedLayers,
    popupData, setPopupData,
    ccmq, setCcmq,
    userData, setUserData

  }


  return (
    <AppContext.Provider value={data}>
      <BrowserRouter>
        <div className='App '>
          <Router />
          <ToastContainer />
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
