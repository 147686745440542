import React, { useEffect, useState } from 'react'
import { GetContext } from '../../../../shared/Context';
import { capitalizeOnlyFirst, isObjEmpty } from '../../../../../utils/util'


function checkObject(arr) {

    // check if arr is array
    const result = Array.isArray(arr);

    if (result) {
        return true
    }
    return false

}


const CCMPrint = () => {
    const [data, setData] = useState()
    const myContext = GetContext()
    const [dedicated, setDedicated] = useState({})


    useEffect(() => {
        let base = {}
        setData(myContext.ccmq)

        if (myContext.ccmq['dedicated']) {
            myContext.ccmq.dedicated.map(el => {
                if (checkObject(el)) {
                    base[el[0]?.layer_name] = el
                }
            })

        }
        setDedicated(base);

    }, [myContext.ccmq])

    return (
        <div className='hidden text-xs'>
            {data && <div id='ccmq_comp' className='text-start px-2 bg-white text-black'>
                {!isObjEmpty(data) && Object.entries(data).map(e => {
                    if (e[0] === 'general') {
                        return Object.values(e[1])
                            .map((dt, index) =>
                                Object.entries(dt)
                                    .map(d => {

                                        return (<div key={d[0]}>
                                            <p className='text-lg font-semibold text-center mb-4'>{d[0].split("_").map(cp => capitalizeOnlyFirst(cp)).join(" ")}</p>
                                            {d[1] && d[1]
                                                .map((entry, ind) => {

                                                    return (entry && <div key={entry?.ccm_q_number} >
                                                        <div className=''>
                                                            <p id=''>প্রশ্নঃ {entry.ccm_q_number} : {entry.ccm_q_bn}</p>

                                                            {(data && data[d[0]] && data[d[0]][entry.ccm_q_number] && data[d[0]][entry.ccm_q_number].ans) && (<p>উত্তরঃ {data[d[0]][entry.ccm_q_number]?.ans} </p>)}
                                                            {(data && data[d[0]] && data[d[0]][entry.ccm_q_number] && data[d[0]][entry.ccm_q_number].comment) && (<p>মন্তব্যঃ {data[d[0]][entry.ccm_q_number]?.comment} </p>)}

                                                            <br />
                                                            <hr />
                                                        </div>
                                                    </div>)
                                                }
                                                )}
                                            <div className='html2pdf__page-break'></div>
                                        </div>)
                                    }
                                    )
                            )

                    }
                })}
            </div>}

            {!(!Object.keys(dedicated).length) &&
                <div className='text-start'>

                    {Object.entries(dedicated).map(e => {
                        return !(!e.length) && (<div key={`ccm_dedicated_${e[0]}`} id={`ccm_dedicated_${e[0]}`}>
                            <p>{e[0]?.split('_').map(ch => capitalizeOnlyFirst(ch)).join(" ")}</p>
                            {e[1].map(entry => {
                                return (<div key={entry.ccm_q_number} className=''>
                                    <p id=''>প্রশ্নঃ {entry.ccm_q_number} : {entry.ccm_q_bn}</p>
                                    {data[e[0]] && (<p>উত্তরঃ {data[e[0]][entry.ccm_q_number]?.ans} </p>)}
                                    {data[e[0]] && (<p>মন্তব্যঃ {data[e[0]][entry.ccm_q_number]?.comment} </p>)}
                                    <br />
                                    <hr />
                                </div>)
                            })}
                        </div>)


                    })}
                </div>}
        </div>

    )
}

export default CCMPrint