
const capitalizeFirstLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  } 

export const nullChecker = cell => (!cell ? "N/A" : cell);
export const intersectValue = cell => (cell ? "YES" : "NO")
export const distanceFormatter = cell => {
    if (cell !== 0 && cell) {
        return cell.toFixed(2)
    }
    else { return 0 }
}

export const statusCellColor = cell => {

    switch (cell) {
        case 'Returned':
            return { background: "#9C27B0" }

        case 'Rejected':
            return { background: "red" }

        case "Approved":
            return { background: "green" }

        default:
            return { background: "#FFCD01" }

    }


}

export const cellDataFormatter = cell => {

    if (cell===null) {
        return "N/A"
    }
    else {
        if (typeof (cell) === 'number') {
            return cell.toFixed(2)
        }
        else if (typeof (cell) === 'boolean') {
            if (cell == 'true' || cell ) {
                return "Yes"
            }
            else {
                return "No"
            }
        }
    }
    return capitalizeFirstLetter(cell)
    // let comp = cell;
    // switch (typeof (cell)) {
    //     case undefined:
    //         comp = 'N/A'
    //         break;
    //     case null:
    //         comp = 'N/A'
    //         break;
    //     case true:
    //         comp = "Yes"
    //         break;
    //     case false:
    //         comp = "No"
    //         break;
    //     case 'true':
    //         comp = "Yes"
    //         break;
    //     case 'false':
    //         comp = "No"
    //         break;
    //     default:
    //         comp = cell
    // }
    // return comp
}

// export const distanceFormatter = cell => (cell.toFixed(2))