import React, { useEffect, useState } from 'react'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import axios from 'axios'
import Loader from './Loader'
import TableBuilder from './TableBuilder'
import jsPDF from 'jspdf'
import * as logoImage from '../../../assets/images/gob.png'
import { cellDataFormatter } from '../../shared/TableCellHelper'
import CCMCheckList from './CCMCheckList'
import { getPDFfromHTMLElement, getPDFfromHTMLElement2, getPDFfromHTMLElement3, mergePdfs } from '../../../utils/util'





const dummy = {
  "Batiaghata": {
    "brickresult": {
      "brickfield": "6"
    },
    "salinityresult": [
      {
        "upazila": "Batiaghata",
        "soil_salinity": "< 2 MMHOS/cm",
        "coverage_in_percentage": 6.202663535514425
      },
      {
        "upazila": "Batiaghata",
        "soil_salinity": "4 - 8 MMHOS/cm",
        "coverage_in_percentage": 86.06397455140669
      },
      {
        "upazila": "Batiaghata",
        "soil_salinity": null,
        "coverage_in_percentage": 0.063670071764063
      }
    ],
    "climateStressResult": [
      {
        "climate": "Severe Storm Surge",
        "coverage_in_percentage": 65.69798268197393
      }
    ],
    "soilMoistureResult": [
      {
        "soli_moisture": "Mixed 100-200 & 200-300 MM",
        "coverage_in_percentage": 12.89687085260929
      },
      {
        "soli_moisture": "Mixed 200-300 & 100-200 MM",
        "coverage_in_percentage": 2.7045682239530793
      },
      {
        "soli_moisture": "Mostly 100 - 200 MM",
        "coverage_in_percentage": 1.2336098988190396
      },
      {
        "soli_moisture": "Mostly 200 - 300 MM",
        "coverage_in_percentage": 72.94318293302999
      },
      {
        "soli_moisture": "Predominantly 200 - 300 MM",
        "coverage_in_percentage": 2.4884061785096967
      },
      {
        "soli_moisture": null,
        "coverage_in_percentage": 0.063670071764063
      }
    ],
    "deltaHostspotResult": [
      {
        "hotspot": "Coastal Zone",
        "coverage_in_percentage": 99.68765070322068
      },
      {
        "hotspot": "Urban Area",
        "coverage_in_percentage": 0.31234929677919226
      }
    ],
    "asalinityresult1": [
      {
        "upazila": "Batiaghata",
        "soil_salinity": "< 2 MMHOS/cm",
        "coverage_in_percentage": 6.202663535514425
      },
      {
        "upazila": "Batiaghata",
        "soil_salinity": "4 - 8 MMHOS/cm",
        "coverage_in_percentage": 86.06397455140669
      },
      {
        "upazila": "Batiaghata",
        "soil_salinity": null,
        "coverage_in_percentage": 0.063670071764063
      }
    ],
    "aclimateStressResult1": [
      {
        "climate": "Severe Storm Surge",
        "coverage_in_percentage": 65.69798268197393
      }
    ],
    "asoilMoistureResult1": [
      {
        "soli_moisture": "Mixed 100-200 & 200-300 MM",
        "coverage_in_percentage": 12.89687085260929
      },
      {
        "soli_moisture": "Mixed 200-300 & 100-200 MM",
        "coverage_in_percentage": 2.7045682239530793
      },
      {
        "soli_moisture": "Mostly 100 - 200 MM",
        "coverage_in_percentage": 1.2336098988190396
      },
      {
        "soli_moisture": "Mostly 200 - 300 MM",
        "coverage_in_percentage": 72.94318293302999
      },
      {
        "soli_moisture": "Predominantly 200 - 300 MM",
        "coverage_in_percentage": 2.4884061785096967
      },
      {
        "soli_moisture": null,
        "coverage_in_percentage": 0.063670071764063
      }
    ],
    "asalinityresult2": [
      {
        "upazila": "Batiaghata",
        "soil_salinity": "< 2 MMHOS/cm",
        "coverage_in_percentage": 6.202663535514425
      },
      {
        "upazila": "Batiaghata",
        "soil_salinity": "4 - 8 MMHOS/cm",
        "coverage_in_percentage": 86.06397455140669
      },
      {
        "upazila": "Batiaghata",
        "soil_salinity": null,
        "coverage_in_percentage": 0.063670071764063
      }
    ],
    "aclimateStressResult2": [
      {
        "climate": "Severe Storm Surge",
        "coverage_in_percentage": 65.69798268197393
      }
    ],
    "asoilMoistureResult2": [
      {
        "soli_moisture": "Mixed 100-200 & 200-300 MM",
        "coverage_in_percentage": 12.89687085260929
      },
      {
        "soli_moisture": "Mixed 200-300 & 100-200 MM",
        "coverage_in_percentage": 2.7045682239530793
      },
      {
        "soli_moisture": "Mostly 100 - 200 MM",
        "coverage_in_percentage": 1.2336098988190396
      },
      {
        "soli_moisture": "Mostly 200 - 300 MM",
        "coverage_in_percentage": 72.94318293302999
      },
      {
        "soli_moisture": "Predominantly 200 - 300 MM",
        "coverage_in_percentage": 2.4884061785096967
      },
      {
        "soli_moisture": null,
        "coverage_in_percentage": 0.063670071764063
      }
    ],
  },
  "Gaurnadi": {
    "brickresult": {
      "brickfield": "3"
    },
    "salinityresult": [
      {
        "upazila": "Gaurnadi",
        "soil_salinity": "< 2 MMHOS/cm",
        "coverage_in_percentage": 99.99999999999987
      }
    ],
    "climateStressResult": [
      {
        "climate": "Severe Flood",
        "coverage_in_percentage": 23.451052296370644
      }
    ],
    "soilMoistureResult": [
      {
        "soli_moisture": "Mixed 200-300 & 100-200 MM",
        "coverage_in_percentage": 3.127733839666175
      },
      {
        "soli_moisture": "Mixed 300-400 & 200-300 MM",
        "coverage_in_percentage": 1.653801081016816
      },
      {
        "soli_moisture": "Mostly > 400 MM",
        "coverage_in_percentage": 0.1464782699902564
      },
      {
        "soli_moisture": "Mostly 200 - 300 MM",
        "coverage_in_percentage": 57.07016723200916
      },
      {
        "soli_moisture": "Mostly 300-400 WS 100-200 MM",
        "coverage_in_percentage": 3.508373917136816
      },
      {
        "soli_moisture": "Predominantly 200 - 300 MM",
        "coverage_in_percentage": 34.49344566018064
      }
    ],
    "deltaHostspotResult": [
      {
        "hotspot": "Coastal Zone",
        "coverage_in_percentage": 94.63527808333326
      },
      {
        "hotspot": "River Systems & Estuaries",
        "coverage_in_percentage": 5.3647219166665305
      }
    ]
  }
}
const formatString = (str) => {
  return str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const formatColumnHeader = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const Analiser = () => {
  const base = process.env.REACT_APP_BACKEND_IP_ASSETS
  const [details, setDetails] = useState(null)
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [ccm, setCcm] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : 'NO_TOKEN'

  const getColumns = (item) => {


    return Object.keys(item[1][0]).map(el => {
      return {
        'dataField': el,
        'text': formatColumnHeader(el),
        'headerStyle': { backgroundColor: '#00000022' },
        'formatter': cellDataFormatter,
      }
    })

  }

  const generatePDF = () => {

    setIsLoading(true)
    const doc = new jsPDF();
    let currentPage = 1;
    let currentY = 10; // Initial Y position

    const header = () => {
      doc.setFillColor(122, 119, 119, 0.266); // Background color (#000000dd)
      doc.rect(0, 28, doc.internal.pageSize.width, .5, 'F'); // Draw a filled rectangle for the header

      doc.addImage(logoImage.default, 'JPEG', 10, 5, 20, 20);
      doc.setFontSize(16);
      doc.setTextColor(0); // Text color (white)
      doc.setFont('helvetica', 'bold');
      doc.text("Planning Information System (PLIS)", 40, 15);
      doc.setFontSize(12);
      doc.text("Programming Division, Bangladesh Planning Commission", 40, 20);
      currentY = 40; // Update the currentY value to account for the header height
    };

    const footer = () => {
      const footerText = `Design & Developed By Tiller ©${new Date().getFullYear()} Bangladesh Planning Commission. All rights reserved.`;
      const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const centerX = (doc.internal.pageSize.width - textWidth) / 2;

      doc.setFontSize(8);
      doc.setTextColor(0); // Text color (black)
      doc.text(footerText, 45, doc.internal.pageSize.height - 5);
      const footerText2 = `Page - ${currentPage}`;

      doc.setFontSize(8);
      doc.setTextColor(0); // Text color (black)
      doc.text(footerText2, doc.internal.pageSize.width - 30, doc.internal.pageSize.height - 5);
    };



    /* if (details) {
      header()

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal')
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, doc.internal.pageSize.width - 30, 50, { align: 'center' });
      let contentY = 50;
      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold')
      doc.text('Project Details', 20, contentY, { align: 'left' })

      doc.rect(30, contentY + 5, doc.internal.pageSize.width - 60, .5, 'F');

      contentY += 20
      Object.entries(details).forEach(([key, value], index) => {
        if (index) {
          doc.setFont('Helvetica', 'normal')
        }
        
        doc.setFontSize(12);
        doc.setTextColor(0); // Text color (black)
        doc.text(`${key.split('_').map(el => `${el[0].toUpperCase()}${el.slice(1)}`).join(" ")}: ${value}`, 20, contentY, { align: 'left' });
        contentY += 10;
      });

      footer()
      doc.addPage()
      currentPage++;
    } */

    Object.entries(data).forEach((entry, ind) => {
      const [upazila, upazilaData] = entry;
      const upazilaName = formatString(upazila);
      const upazilaTitleHeight = 10; // Height of the Upazila title

      if (ind) {
        doc.addPage();
        currentPage++;
        currentY = 10;
        footer()

      }
      else {
        footer()
      }

      header(); // Add header to each page
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold')
      doc.setTextColor(0);
      doc.text('Analysis Report', 10, currentY)
      doc.text(`Upazila - ${upazilaName}`, 10, currentY + 10);
      currentY += upazilaTitleHeight;
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal')
      Object.entries(upazilaData).forEach((el, index) => {

        if (index) {
          const columnHeaders = Object.keys(el[1][0]);
          const tableHeight = 8 * el[1].length; // Approximate row height
          const tableTitleHeight = 12; // Height of the table title
          const totalTableHeight = tableTitleHeight + tableHeight;

          if (currentY + tableHeight > doc.internal.pageSize.height - 20) {
            doc.addPage();
            currentPage++;
            currentY = 10;
            footer()
            header(); // Add header to each new page

            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal')
            doc.setTextColor(0);
          }

          const textWidth = doc.getStringUnitWidth(`${formatString(el[0])}`) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          const centerX = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(`${formatString(el[0])}`, centerX, currentY);
          currentY += tableTitleHeight;

          doc.autoTable({
            head: [columnHeaders.map(item => formatColumnHeader(item))],
            body: el[1].map(row => Object.values(row)),
            startY: currentY,
          });

          currentY += tableHeight + 20; // 10 is padding between tables

        }
        else {
          
          console.log(el[1]['brickfield']);
          doc.text(`${formatString(el[0])} ${el[1]['brickfield']}`, 20, currentY + 5);
          
          // doc.text(`${formatString(el[0])}`, centerX, currentY);
        }
      })

      // Add footer to each page
    });
    const generel_ccmq = getPDFfromHTMLElement('general_ccmq', 'p')
    const division_ccmq = getPDFfromHTMLElement('division_ccmq', 'p')
    const cover = getPDFfromHTMLElement3('project_cover')
    const file_name = `pps_${details?.pps_id}_${new Date().getTime()}.pdf`

    mergePdfs([cover, doc, generel_ccmq, division_ccmq], file_name)
      .then(response => {


        const file = new File([response], file_name, { type: 'application/pdf' })
        const formData = new FormData();
        formData.append('file', file, `${file_name}`);
        const url = `${base}/pps/submitpdf`
        setIsLoading(true)
        axios.put(url, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then(res => {

            if (res.data.status === 'success') {
              setIsLoading(false)
              // Handle success here, e.g., show a modal
              // doc.save(file_name);
              setOpenModal(true); // Assuming you have a state variable for modal visibility
            }
          })
          .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
            setIsLoading(false)
            console.log(e);
          })

      })






  };



  const getData = () => {
    const url = `${base}/pps/dpp/details`


    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {

        const { pps_id, project_name, district, agency_name, sector, sub_sector, project_start_date, project_end_date } = res.data.dppdetails
        setDetails({ pps_id, project_name, district, agency_name, sector, sub_sector, project_start_date, project_end_date })

      })
      .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })
  }
  const basicData = () => {
    const url = `${base}/pps/basicresult`

    setIsLoading(true)
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        setIsLoading(false)

        setData(res.data);
      })
      .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        } setIsLoading(false); console.log(e) })
  }
  const getCCM = () => {
    const url = `${base}/pps/ccm`

    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        setCcm(res.data);
      })
      .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })
  }
  useEffect(() => {
    basicData()
    getData()
    getCCM()
  }, [])



  return (
    <div className=''>
      <Header addStyle=' sticky top-0 z-10' />


      {isLoading ? <Loader /> :

        (<div className='  '>
          {data && !openModal ? (<div className='w-full px-[15%] py-10'>
            <div className=' w-full   '>
              {details ?
                (<div className='text-left py-4' id='project_cover' key={'project_cover'}>
                  <p className='font-semibold text-3xl mb-4 text-center'>Project Details</p>
                  {Object.entries(details).map(([key, value], index) => {

                    return (value ? <p><span className='font-semibold'>{`${key.split('_').map(el => `${el[0].toUpperCase()}${el.slice(1)}`).join(" ")}`}</span> : {`${value}`}</p> : <></>)
                  })}
                </div>)
                : <></>
              }
            </div>
            <div>

              <CCMCheckList dataFor={'general_ccmq'} data={ccm?.length && ccm[0]['ccmq_general_25.2']} name={"General"} />
              <CCMCheckList dataFor={'division_ccmq'} data={ccm?.length && ccm[1].ccmq_division_wise} name={"Division Wise"} />


            </div>
            {Object.entries(data).map(el => {
              return (<div key={el[0]} className='  py-10 '>
                <div className=' p-10 shadow-md border'>
                  <h2 className='text-2xl font-semibold  w-fit  mb-2'>Upazila - {el[0]}</h2>

                  <div className='px-10'>

                    {Object.entries(el[1]).map((item, index) => {
                      return index ? (<>
                        <p className='py-2 text-[1rem] font-semibold text-center'>{formatString(item[0])}</p>
                        <TableBuilder
                          id={`table_${item[0]}`}
                          keyField={'aaa'}
                          data={item[1]}
                          columns={getColumns(item)}

                        /></>) : (<p className='text-start capitalize text-lg font-[500]'>{item[0]} - {item[1]['brickfield']}</p>)

                    })}
                  </div>
                </div>

              </div>)
            })}
            <button onClick={generatePDF}
              className='mb-28 bg-gray-500 shadow-sm px-4 py-2 rounded-lg  text-white text-lg hover:bg-opacity-90 hover:shadow-lg'>
              Submit PDF</button>

          </div>) : !openModal ? (<div className='py-20'>{token === 'noTOKEN' ? 'Unauthorised Action' : 'NO DATA'}</div>) : <></>}

        </div>)}
      {/* Modal */}
      {openModal && (
        <div className=" container absolute h-[30vh] overflow-hidden z-10 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ">
          <div className="modal-content">
            <p className='font-semibold text-lg'>PDF Submitted Successfully!</p>
            <button
              onClick={() => setOpenModal(false)}
              className="hover:bg-[#b87575] m-4 px-5 py-2 bg-[#706d6d67] bg-opacity-25 w-fit mx-auto rounded-md"
            >
              Exit
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Analiser