
import React, { useEffect } from 'react'
import { Button } from '@mui/material';
import { GetContext } from '../../../../shared/Context';
import CircularProgress from '@mui/material/CircularProgress';

import { useState } from 'react';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router';


import CCMPrint from "./CCMPrint"
import {
    addText, addImage, addPageNum,
    capitalizeOnlyFirst, getMapTitle, getYPos,
    isObjEmpty, mergePdfs, getPDFfromHTMLElement, getLegendTitle,
} from '../../../../../utils/util'
import axios from 'axios';



const LEGENDSRC = (key) => `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=${key}&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0xFFFFFF;fontSize:10;bgColor:0x051E34;dpi:180`

var dims = {
    a0: [1189, 841],
    a1: [841, 594],
    a2: [594, 420],
    a3: [420, 297],
    a4: [297, 210],
    a5: [210, 148]
};




const PrintComponent = ({ }) => {
    const [loading, setLoading] = useState(false)
    const [mapLoad, setMapLoad] = useState(false)
    const [alldocs, setalldocs] = useState()
    const userData = JSON.parse(localStorage.getItem('user'))
    const myContext = GetContext()
    const base_url = process.env.REACT_APP_BACKEND_IP

    const navigate = useNavigate()
    const addHeader = (doc) => {
        doc.line(0, 30, doc.internal.pageSize.getWidth(), 30);
        addImage({
            docEl: doc,
            src: "/img/header_logo.png",
            posX: 70,
            posY: 6,
            width: 18,
            height: 18,
            format: "png"
        });
        const title = "Planning Information System (PLIS)";
        const sub = "Programming Division, Bangladesh Planning Commission";

        addText({
            docEl: doc,
            text: title,
            posX: 90,
            posY: 14,
            color: "#000000",
            style: "bold",
            size: "24"
        });
        addText({
            docEl: doc,
            text: sub,
            posX: 90,
            posY: 22,
            color: "#000000",
            style: "normal",
            size: "14"
        });
    }
    const addFooter = (doc) => {

        doc.line(0, doc.internal.pageSize.getHeight() - 10,
            doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight() - 10);
        const devText = "Design & Developed By Tiller";
        const copyRight = `©️${new Date().getFullYear()} Bangladesh Planning Commission. All rights reserved.`;



        addText({
            docEl: doc,
            text: devText,
            posX: 4,
            posY: doc.internal.pageSize.getHeight() - 4,
            color: "#000000",
            style: "normal",
            size: "10"
        });

        addText({
            docEl: doc,
            text: copyRight,
            posX: doc.internal.pageSize.getWidth() / 4,
            posY: doc.internal.pageSize.getHeight() - 4,
            color: "#000000",
            style: "normal",
            size: "10"
        });
    }

    useEffect(() => {
        if (alldocs && mapLoad) {

        }
    }, [alldocs, mapLoad])

    const createTitlePage = (doc) => {
        doc.setFillColor('#FFFFFF')
        addHeader(doc)
        addFooter(doc)

        /* Page Title */
        addText({
            docEl: doc,
            text: "Project Details & Information".toUpperCase(),
            posX: 30,
            posY: 50,
            color: "#000000",
            style: "bold",
            size: "24"
        });

        doc.line(20, 58, doc.internal.pageSize.width - 70, 58)
        let posY = 64;

        addText({
            docEl: doc,
            text: "Project Name :",
            posX: 30,
            posY: posY,
            color: "#000000",
            style: "bold",
            size: "14"
        });


        let text = myContext.projectData.project_name
        doc.internal.pageSize.rightMargin = 40;
        const pageWidth = doc.internal.pageSize.width - 70 - doc.internal.pageSize.rightMargin
        const textArray = doc.splitTextToSize(text, pageWidth);

        doc.setFontSize(12);
        for (let i = 0; i < textArray.length; i++) {
            doc.text(textArray[i], 70, posY + (i * 7));
        }

        posY += getYPos(myContext.projectData.project_name, doc) - 12

        addText({
            docEl: doc,
            text: "Authority :",
            posX: 30,
            posY: posY,
            color: "#000000",
            style: "bold",
            size: "14"
        });

        addText({
            docEl: doc,
            text: myContext.projectData.authority,
            posX: 70,
            posY: posY,
            color: "#000000",
            style: "bold",
            size: "12"
        })

        text = myContext.projectData.authority
        posY += getYPos(text, doc)

        myContext.basicProjectData && Object.entries(myContext.basicProjectData).map(el => {

            addText({
                docEl: doc,
                text: el[0].split("")[0].toUpperCase() + el[0].slice(1, el[0].length),
                posX: 30,
                posY: posY,
                color: "#000000",
                style: "bold",
                size: "14"
            })

            addText({
                docEl: doc,
                text: el[1] ? el[1].split("")[0].toUpperCase() + el[1].slice(1, el[1].length) : 'N / A',
                posX: 70,
                posY: posY,
                color: "#000000",
                size: "12"
            })

            posY += getYPos(el[1] || 'N / A', doc)

        })

    }


    const addTable = (doc, name) => {
        const tableElement = document.getElementById('table_' + name)

        doc.addPage()

        doc.autoTable({
            html: tableElement,
            startY: 50,
            margin: {
                top: 20
            }
        });
        addFooter(doc)

    }

    const exportdoc = (maps) => {
        setLoading(true)
        setMapLoad(false)
        let count = 0
        const format = 'a4';
        const dim = [297, 210];
        const resolution = 72;

        const doc = new jsPDF('l', undefined, format);


        /* Page  1 Construction */
        createTitlePage(doc)
        /* ccmq 25.2  -html2pdf */
        /* ccmq 25.3  -html2pdf */
        const genereal_ccmq = getPDFfromHTMLElement('ccmq_comp')

        const width = Math.round((dim[0] * resolution) / 25.4);
        const height = Math.round((dim[1] * resolution) / 25.4);
        var size;
        var viewResolution;
        var dedicated_ccmq = {}
        var map_table_docs = {}
        Object.entries(maps).map((map, index) => {
            const doc1 = new jsPDF('l', undefined, format);
            size = map[1].getSize();
            viewResolution = map[1].getView().getResolution();

            dedicated_ccmq[map[0]] = getPDFfromHTMLElement(`ccm_dedicated_${map[0]}`)

            map[1].on('rendercomplete', () => {
                const mapCanvas = document.createElement('canvas');
                mapCanvas.width = width;
                mapCanvas.height = height;
                const mapContext = mapCanvas.getContext('2d');
                const renderedMap = document.querySelectorAll(`.map${map[0]} canvas`);
                renderedMap.forEach(element => {
                    if (element.width > 0) {
                        const opacity = element.parentNode.style.opacity;
                        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                        const transform = element.style.transform;
                        // Get the transform parameters from the style's transform matrix
                        const matrix = transform
                            .match(/^matrix\(([^\(]*)\)$/)[1]
                            .split(',')
                            .map(Number);
                        // Apply the transform to the export map context
                        CanvasRenderingContext2D.prototype.setTransform.apply(
                            mapContext,
                            matrix
                        );
                        mapContext.drawImage(element, 0, 0);
                    }
                });
                mapContext.globalAlpha = 1;
                mapContext.setTransform(1, 0, 0, 1, 0, 0);
                doc1.internal.pageSize.leftMargin = 40;
                doc1.internal.pageSize.rightMargin = 40;
                addText({
                    docEl: doc1,
                    text: getMapTitle(map[0]),
                    posX: doc1.internal.pageSize.width / 3 + 25,
                    posY: 10,
                    color: "#000000",
                    style: 'bold',
                    size: '16'
                });
                doc1.addImage(
                    mapCanvas.toDataURL('image/jpeg'),
                    'JPEG',
                    10,
                    20,
                    doc1.internal.pageSize.getWidth() - 20,
                    dim[1] - 50
                );
                doc1.rect(8, 18, doc1.internal.pageSize.getWidth() - 16, dim[1] - 46, 'S')

                addImage({
                    docEl: doc1,
                    src: "/img/north.png",
                    posX: doc1.internal.pageSize.getWidth() - 20,
                    posY: 22,
                    width: 8,
                    height: 8
                    ,
                    format: "png"
                });

                const legend = new Image()
                legend.src = LEGENDSRC(map[0]);

                doc1.rect(doc1.internal.pageSize.getWidth() - 50, dim[1] - 80, 40, 50, 'F')

                addText({
                    docEl: doc1,
                    text: getLegendTitle(map[0]),
                    posX: doc1.internal.pageSize.getWidth() - 38,
                    posY: dim[1] - 64,
                    size: 12,
                    color: '#ffffff',
                    style: 'normal'
                })
                doc1.addImage(
                    legend,
                    'JPEG',
                    doc1.internal.pageSize.getWidth() - 50, dim[1] - 60, 40, 30
                );

                addFooter(doc1)

                map[1].setSize(size);
                map[1].getView().setResolution(viewResolution);


                addTable(doc1, map[0])
                /* CCMQ For Dedicated */

                addPageNum(doc1);

                map_table_docs[map[0]] = doc1
                if (Object.keys(maps).length - 1 == index) {
                    setMapLoad(true)
                }
            });


            if (Object.keys(maps).length - 1 == index) {
                const all_doc_collection_list = [doc, genereal_ccmq]
                genereal_ccmq.then(ccmq => {

                    Object.entries(map_table_docs).map(el => {

                        if (dedicated_ccmq[el[0]]) {
                            all_doc_collection_list.push(map_table_docs[el[0]])
                            all_doc_collection_list.push(dedicated_ccmq[el[0]])
                        }
                        else {
                            all_doc_collection_list.push(map_table_docs[el[0]])
                        }
                    });

                    const file_name = `${myContext.projectData.id}_${myContext.projectData?.type === 'point' ? '0'
                        : myContext.projectData?.type === 'line' ? 1 : 2}_${userData.userId}_${new Date().getTime()}`;

                    mergePdfs(all_doc_collection_list, file_name)
                        .then(response => {
                            const formData = new FormData();
                            formData.append('file', response, `${file_name}.pdf`);

                            formData.append('dppType', myContext.projectData?.type)
                            formData.append('dppId', myContext.projectData.id)

                            axios.put(`${base_url}/pdf/upload`, formData, {
                                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                            })
                                .then(res => {

                                })
                                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                                    console.log(e);
                                })
                        })

                    setLoading(false)
                })
            }
            var printSize = [width, height];
            map[1].setSize(printSize);

        })

    }


    const handleDownload = () => {
        exportdoc(myContext.maps)
    }

    return (
        <>
            {!loading ? (<>
                <CCMPrint />
                <Button variant="contained" name="Download" color="success" style={{ margin: '2px', textTransform: 'none' }} onClick={handleDownload}>Preview</Button>
            </>) :
                (<CircularProgress className='my-auto' />)}
        </>
    )
}

export default PrintComponent