import React from 'react'
import { routes } from './WrappedRoutes/createRoute'
import { Routes, Route, } from "react-router"
import PageNotFound from '../../DefaultPage/PageNotFound'
import { GetContext } from '../../shared/Context'


const Router = () => {

    
    return (
        <div>
            <Routes>
                {routes.map((route, index) => (
                    <Route  exact path={route.path} element={route.element}></Route>
                ))}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    )
}

export default Router;