import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import Header from '../shared/Header'
import { Button } from '@mui/material'
import FileInput from './FileInput';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import showToast from '../../utils/Toast';
import { GetContext } from '../shared/Context';
import { isLoggedIn } from '../../utils/util';


const FilUpload = () => {
  const navigate = useNavigate()
  const myContext = GetContext()
  const [file, setFile] = useState(null)
  const base_url = process.env.REACT_APP_BACKEND_IP
  const { id, project_name, district, authority, type, submission_date } = myContext.projectData || {}

  const handleBack = () => {
    navigate('/allprojects')
  }


  useEffect(() => {
      if (!isLoggedIn()) {
        navigate('/')
      }
    }, [])


  const handleSubmit = (formData) => {

    if (file) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("kml_file", file);
      const url = 'http://plismap.plancomm.gov.bd:8050/v1/dpp/line/geom/'
      axios.put(url, formData,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, })
        .then(res => {
          showToast("File Uploaded Successfully!")
          setTimeout(() => {
            navigate(-1)
          }, 750);

        })
        .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
          console.log(e);
        })
    }
    else {
      showToast({
        message: 'No File Selected',
        type: 'error'
      })
    }
  }



 

  return (
    <div>
      <Header />
      <div className='shadow-md spatial-uploader-container text-start w-[40%] mx-auto px-10 bg-[#F2F2F2] py-4 mt-24'>
        {/* <div>
          
          <p>Project name: <span className='ml-2'>{project_name || 'N/A'}</span></p>
          <p>Id: <span className='ml-2'>{id || 'N/A'}</span></p>
          <p>District: <span className='ml-2'>{district || 'N/A'}</span></p>
          <p>Authority: <span className='ml-2'>{authority || 'N/A'}</span></p>
          <p>Type: <span className='ml-2'>{type || 'N/A'}</span></p>
          <p>Submission Date: <span className='ml-2'>{submission_date || 'N/A'}</span></p>
        </div> */}
        <p className=''>Attachment</p>
        <FileInput file={file} setFile={setFile} />
        <p className=' text-end'>
          *KML
        </p>
        <div>
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </div>
    </div>
  );
}
export default FilUpload