import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

const TextFieldInput = ({ id, label, inputType = 'number', name, disabled, dValue = false, setter = false }) => {


    const [value, setValue] = useState("")
    const [isVisible, setVisible] = useState(false)
    const handleChange = (e) => {
        setValue(e.target.value)
        setter && setter(name, e.target.value)
    }


    return (
        <Box

            sx={{
                '& > :not(style)': { m: 1, width: { xs: '32ch', md: '25ch' } }, display: 'flex', justifyContent: 'center'
            }}
            noValidate
            autoComplete="off"
        >
            <FormControl sx={{ minWidth: { xs: '100px', md: '300px' }, display: inputType === 'password' ? 'flex' : '' }}>
                <div>

                </div>
                <TextField size='small'
                    id={id}
                    disabled={disabled}
                    label={label}
                    name={name || label}
                    variant="outlined"
                    onChange={handleChange}
                    value={!dValue ? value : dValue}
                    type={inputType !== 'password' ? inputType : !isVisible ? inputType : 'text'}
                    InputProps={{
                        endAdornment:
                        inputType === 'password'&& isVisible ? <VisibilityOffIcon className='hover:cursor-pointer' onClick={(e) => setVisible(!isVisible)} /> :
                        inputType === 'password'&&      <VisibilityIcon className='hover:cursor-pointer' onClick={(e) => setVisible(!isVisible)} />,

                    }} >

                    {(inputType === 'password' && isVisible) && <div onClick={(e) => setVisible(!isVisible)}><VisibilityIcon /> </div>}
                    {(inputType === 'password' && !isVisible) && <div onClick={(e) => setVisible(!isVisible)}> <VisibilityOffIcon /> </div>}
                </TextField>
            </FormControl>
        </Box>
    );
}

export default TextFieldInput