import React, { useEffect } from 'react'
import { useContext, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

const dummyData = [

    { type: 'Agricuture',
     pr: `Animal Shelter, Aquaculture, Arboryculture, Dairy Firming, Irrigation Facilities (Irrigation Canal, Culvert, FLood Wall etc.)`,
      cpr: 'Communication Tower Within Permitted Height, Crematorium, Graveyard / Cemetery ', rpr: 'All uses except permitted and conditionally permited uses.' },
    { type: 'Residential', 
    pr: 'Departmental Stores, Doctor / Denist Chamber, Drug Stores or Pharmacy, Dwelling',
     cpr: 'Graveyard / Cemetery, Market (Kacha Bazar) Place, Police Station, Sports and Recreation Club, Flood Management Structure, ', rpr: 'All uses except permitted and conditionally permited uses.' },
    {
        type: 'Waterbody', pr: `Aquatic Recreation Facility (Without Structure), Fishing Club (Non-structural),utilty Lines,Water Parks`,
        cpr: `Marina / Boating Facility, Water based Recreation`, rpr: 'All uses except permitted and conditionally permited uses.'
    },
]
const dummyCol = [
    { dataField: 'type', text: 'Type' },
    { dataField: 'pr', text: 'Permitted' },
    { dataField: 'cpr', text: 'Conditionally Permitted' },
    // { dataField: 'rpr', text: 'Restricted' },
]

const TableBuilder = ({ keyField = 'pr', data = dummyData, columns = dummyCol, law, id }) => {
    const lawColumn = [
        {
            dataField: "law",
            headerAlign: "center",
            text: law ? `**${law[0]}` : "",
        },
    ]
    return (<div className='mb-2'>
        <BootstrapTable
            id={id}
            classes='mb-0'
            hover
            bootstrap4
            keyField={keyField}
            data={data}
            columns={columns}
        />
        {law && law[0] && <BootstrapTable
            columns={lawColumn}
            keyField={"law"}
            data={[]}
        />}

        <div></div>
    </div>

    )
}

export default TableBuilder