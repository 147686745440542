import React from 'react'

const ModalContainer = ({ onClose, children }) => {
    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full overflow-auto bg-opacity-50 bg-gray-500">
            <div className="relative w-full top-[20%] p-6 mx-auto my-auto max-w-md">
                <div className="relative flex flex-col w-full bg-white rounded-lg shadow-lg">
                    <div className="flex items-center justify-between px-6 py-4 bg-gray-900 rounded-t-lg">
                        {children}
                    </div>
                </div>
                <p className='absolute top-0 right-0' onClick={onClose}>x</p>
            </div>
        </div>
    )
}

export default ModalContainer