
import React, { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";

import { useNavigate } from "react-router-dom";
import { statusCellColor } from "../../shared/TableCellHelper";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import '../../../css/projects.css'
import { TouchAppOutlined } from "@mui/icons-material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { GetContext } from "../../shared/Context";
import axios from 'axios';
import QueueIcon from '@mui/icons-material/Queue';
import Pagination from '../../shared/Pagination'
import { isLoggedIn } from "../../../utils/util";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const AllProject = ({ status = 'all' }) => {


  const myContext = GetContext()
  const [tableData, setTableData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const base_url = process.env.REACT_APP_BACKEND_IP
  const [paginatedData, setPaginatedData] = useState([])
  const base_url_asset = process.env.REACT_APP_BACKEND_IP_ASSETS
  const navigate = useNavigate()


  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/')
    }
  }, [])

  function capitalizeFirstLetter(str) {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str
  }

  const loadProjects = () => {
    // const id = toast.loading("Loading Projects...")
    axios.get(`${base_url}/dppProjectList/all`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((json) => {
        let count = 0

        const tempTableData = []
        Object.entries(json.data).map((dt) => {
          count += dt[1].length

          dt[1].map((d) => {

            tempTableData.push({
              ...(d), 'key': d.id + "-" +
                d.district, 'type': dt[0], 'district': capitalizeFirstLetter(d.district),
              'idd': tempTableData.length + 1,
            });
            return null;
          })
          return null;
        })

        setDataIsLoaded(true)

        setTableData(tempTableData)
      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }

        console.error(e)
      })
  }
  useEffect(() => {
    loadProjects()
  }, [])




  const viewButtonAction = (row) => {
    myContext.setProjectData(row);
    const id = row?.id
    const type = row?.type
    if (id && type) {
      axios.get(`${base_url}/analysis/basicinfo/dpp_projects_${type}/${id}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, })
        .then(res => {
          myContext.setBasicProjectData(res.data[0])
        })
    }

    if (!row.centerlat) {

      navigate(`/upload`)
    } else {
      navigate('/map')
    }

  }


  const pdfBtn = (cell, row, rowIndex, formatExtraData) => {



    return (
      <button
        style={{ 'fontSize': '12px', marginTop: 'auto', marginBottom: 'auto' }}

        disabled={row.pdf_url}>
        {row.pdf_url ? (<a href={`${base_url_asset}/assets/pdf/${row.pdf_url}`}
          className="pdf-btn "> <div className=" hidden md:inline">View</div> <PictureAsPdfIcon />
        </a>) : (<a

          className="text-[#fff] text-sm  cursor-no-drop shadow-sm hover:text-[#fff] bg-slate-400 py-1 px-2 rounded-md"> NO PDF
        </a>)}


      </button>)


  };




  const viewButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <button className="view-btn"
        onClick={() => {

          viewButtonAction(row);
        }}
      >
        <div style={{ 'fontSize': !row.centerlat && '12px', marginTop: 'auto', marginBottom: 'auto' }}
          className="hidden  md:inline my-auto text-xs col-span-8 ">
          {!row.centerlat ? 'Upload' : 'View'}</div>
        {!row.centerlat ? <AddBoxIcon /> : <TouchAppOutlined />}
      </button>
    );
  };

  const statusText = (row) => {
    return (
      <span className="projectStatus" style={statusCellColor(row)}> {row}</span>
    )
  }


  const columns = [
    {
      dataField: "idd",
      text: "Id",
      // sort: true,
      headerStyle: () => {
        return { width: "5%" };
      },
      classes: (cell, row, rowIndex, colIndex) => {
        return 'pr-col'
      }
    },

    {
      dataField: "project_name",
      text: "Project Name",
      headerStyle: () => {
        return { width: "20%", };
      },
      classes: (cell, row, rowIndex, colIndex) => {
        return 'pr-col'
      }
      // sort: true
    },
    {
      dataField: "authority",
      text: "Department",
      headerStyle: () => {
        return { width: "10%", 'textOverflow': 'ellipsis', overflow: 'hidden', 'whiteSpace': 'nowrap' };
      },
      classes: (cell, row, rowIndex, colIndex) => {
        return 'pr-col'
      }
    },
    {
      dataField: "district",
      text: "Project  Location",
      headerStyle: () => {
        return { width: "9%", 'textOverflow': 'ellipsis', overflow: 'hidden', 'whiteSpace': 'nowrap' };
      }, classes: (cell, row, rowIndex, colIndex) => {
        return 'pr-col'
      }
    },
    /* {
      dataField: "submission_date",
      text: "Submitted",
      headerStyle: () => {
        return { width: "7%", 'textOverflow': 'ellipsis', overflow: 'hidden', 'whiteSpace': 'nowrap' }
      },
      classes: (cell, row, rowIndex, colIndex) => {
        return 'pr-col'
      }
    }, */
    {
      dataField: "View",
      text: "View on Map",
      formatter: viewButton,
      headerStyle: () => {
        return { width: "8%", 'textOverflow': 'ellipsis', overflow: 'hidden', 'whiteSpace': 'nowrap' };
      },

    },
    {
      dataField: 'pdf',
      text: "View PDF",
      formatter: pdfBtn,
      headerStyle: () => {
        return { width: '8%' }
      }
    }
  ];

  const handleAddProject = () => {
    navigate('/addproject')
  }


  return (
    <div>
      <Header addStyle="fixed top-0 z-20" />
      <div className="projects-list-main " style={{}}>
        <div className="pt-10 ">
          <h1 className=" projects-list-title" >{status} projects</h1>
          <div className='list-table' style={{ margin: '0 auto' }}>

            <div >
              <div className="flex justify-between mb-2 items-end">
                {userInfo &&
                  <div className="text-start">
                    {userInfo.userName && <p><b> User Name: </b>{userInfo.userName}</p>}
                    {userInfo.ministryName && <p><b>Ministry: </b>{userInfo.ministryName}</p>}
                    {userInfo.agencyName && <p><b>Agency Name: </b>{userInfo.agencyName}</p>}
                    {userInfo.sectorDivName && <p><b>Division Name: </b>{userInfo.sectorDivName}</p>}
                    {userInfo.sectorName && <p><b>Sector Name: </b>{userInfo.sectorName}</p>}
                  </div>
                }
                <div className="userinfo text-left relative">

                  {userInfo.userType == 2 && (<div className="right-0 " onClick={handleAddProject}>
                    <button className="bg-[#1976D2ef] px-2 py-2 my-auto rounded-lg text-white" variant="contained">
                      <QueueIcon sx={{ mr: 2 }} />Add new Project</button>
                  </div>)}
                </div>
              </div>
              {!dataIsLoaded && <div className="data_loader"></div>}
              {dataIsLoaded && tableData.length !== 0 ? (
                <div>

                  <BootstrapTable
                    hover
                    bootstrap4
                    keyField="key"
                    data={paginatedData}
                    columns={columns}

                  />
                  <Pagination data={tableData} setPaginatedData={setPaginatedData} />
                </div>
              ) : <h6 >No Project Found</h6>}
            </div>

          </div>

        </div>

      </div>
      <Footer />
    </div>
  )
}
export default AllProject;
