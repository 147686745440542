import React, { useEffect, useState } from 'react'
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Footer from '../../shared/Footer'
import Header from '../../shared/Header'
import HomeBg from '../../../assets/images/home-bg.jpg'

import {
    
    Divider,
    FormHelperText,
  
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import showToast from '../../../utils/Toast';
import { Link } from 'react-router-dom';


const Login = () => {

    const [values, setValues] = useState({ email: "", password: "" })
    const userData = JSON.parse(localStorage.getItem('user'))
    const [errors, setErrors] = useState({})

    const [showPassword, setShowPassword] = React.useState(false);

    const navigate = useNavigate();
    const base_url = process.env.REACT_APP_BACKEND_IP
    const base_url_assets = process.env.REACT_APP_BACKEND_IP_ASSETS

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleResDownload = (e) => {
        showToast({message:<p className='text-sm'>Downloading {e.target.innerHTML}</p>})
    

    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    const checkErrors = () => {
        const status = false
        if (values.email.trim() === "" && values.password.trim() === "") {
            setErrors({ email: "Email can't be empty", password: "Password can't be empty" })
        }
        else if (values.email.trim() === "") {
            setErrors({ email: "Email can't be empty", password: "" })
        }
        else if (values.password.trim() === "") {
            setErrors({ email: "", password: "Password can't be empty" })
        }
        else {
            setErrors({ email: "", password: "" })
            return !status
        }
        return status
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkErrors()) {
            const email = values.email;
            const password = values.password

            axios.post(`${base_url}/auth/login`, { email, password })
                .then((response) => {
                    const { token } = response.data;
                    const user = jwt_decode(token);
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    showToast({ message: 'Welcome to PLIS!!' })
                    navigate('/allprojects')
                })
                .catch((error) => {
                    console.log(error);
                    setErrors({ submit: error.response?.data?.message || error?.message || error.code })
                });
        }
    }


    return (
        <div className='h-screen bg-cover' style={{ backgroundImage: `url(${HomeBg})` }}>
            <Header addStyle='' />

            <div
                className=" mx-auto w-[90%] md:w-[80%] lg:w-[55%] h-[24rem] mt-[11rem] flex flex-col justify-between">
                <div className=' h-[80%] flex justify-around px-[3%]'>
                    <div

                        className='text-justify p-4  bg-[#ffffff79] h-full w-[47%] rounded-md overflow-y-hidden hover:overflow-y-scroll '>

                        <p className=''>The Planning Information System (PLIS) is an innovative system designed to enable officials from
                            different wings under Sector Divisions of Bangladesh Planning Commission and from different government agencies
                            to increasingly and effectively use climate risk information during national and local development planning
                            and project formulation and appraisal processes. The system is intended to strengthen the
                            climate resilience of public investment and offers a range of functionalities to make project management easier
                            and more efficient.
                        </p>

                    </div>
                    {!userData && <div className='bg-white bg-opacity-25 h-full w-[47%] rounded-md'>
                        <div className=' mt-2'>
                            <h3 className='font-bold text-3xl'>Login</h3>
                            <form className='pt-2 flex flex-col gap-[.75rem]' onSubmit={handleSubmit}>
                                <div className='flex flex-col  items-start w-full px-4 '>
                                    <label className='text-lg font-semibold' htmlFor="username">Email Address:</label>
                                    <input
                                        style={{ backgroundColor: 'none', opacity: '1' }}
                                        className='w-full py-1 px-2 focus:outline-none rounded-md'
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onChange={handleChange}
                                        placeholder="Enter email address"
                                    />

                                </div>

                                <div className='flex flex-col  items-start w-full px-4 '>
                                    <label className='text-lg font-semibold' htmlFor="password">Password:</label>
                                    <div className='relative w-full'>
                                        <input
                                            style={{ backgroundColor: 'none' }}
                                            className='w-full  py-1 px-2 focus:outline-none rounded-md'
                                            id="-password-login"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            value={values.password}
                                            onChange={handleChange}
                                            placeholder="Enter password"

                                        />
                                        {!showPassword ?
                                            <VisibilityIcon
                                                onClick={handleClickShowPassword}
                                                className='p-1 hover:cursor-pointer hover:bg-slate-400'
                                                sx={{ position: 'absolute', transform: 'translateY(-50%)', top: '50%', right: '10px' }}
                                            />
                                            :
                                            < VisibilityOffIcon
                                                onClick={handleClickShowPassword}
                                                className='p-1 hover:cursor-pointer hover:bg-slate-400'
                                                sx={{ position: 'absolute', transform: 'translateY(-50%)', top: '50%', right: '10px' }}
                                            />
                                        }
                                    </div>



                                </div>
                                <div className='w-[calc(100%-3rem)]  mx-auto text-center'>
                                    <div className='min-h-[1.5rem] '>
                                        {errors.email ? (
                                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                                {errors.email}
                                            </FormHelperText>
                                        ) : errors.password ? (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.password}
                                            </FormHelperText>
                                        ) : errors.submit ? (
                                            <FormHelperText error>{errors.submit}</FormHelperText>

                                        ) : ''
                                        }
                                    </div>


                                    <button className=' py-1 text-white tracking-wider font-semibold bg-blue-500 w-full rounded-md' type="submit">LOGIN</button>
                                </div>
                                <Divider textAlign="right">
                                    <Typography variant="caption">Don't have an Account?
                                        <span onClick={(e) => {
                                            navigate('/register')
                                        }} className='italic bg-slate-400 rounded-md ml-1 px-1 hover:cursor-pointer hover:bg-slate-300'>Sign up</span></Typography>
                                </Divider>
                            </form>

                        </div>

                    </div>}
                </div>

                <div className='bg-white h-[10%] mx-[5%] justify-center text-xs xl:text-sm rounded-md flex items-center '>
                    <div className='text-right font-semibold '>
                        Useful Resources:
                    </div>
                    <div className=' ml-4 '>
                        <ul className='flex justify-around gap-3' >
                            <li className='hover:border-black border-b-2 hover:cursor-pointer  pl-2'>
                                <a onClick={handleResDownload} href={`${base_url_assets}/assets/resources/plis_user_manual.pdf`}>PLIS User Manual
                                </a>
                            </li>
                            |
                            <li className='hover:border-black border-b-2 hover:cursor-pointer  pl-2' >
                                <a onClick={handleResDownload} href={`${base_url_assets}/assets/resources/ccm_report.pdf`}>CCM Report
                                </a></li>

                            |
                            <li className='hover:border-black border-b-2 hover:cursor-pointer  pl-2'>
                                <a onClick={handleResDownload} href={`${base_url_assets}/assets/resources/Climate_Check_Method.pdf`}>Climate Check Method
                                </a>
                            </li>
                            |
                            <li className='hover:border-black border-b-2 hover:cursor-pointer  pl-2' >
                                <a onClick={handleResDownload} href={`${base_url_assets}/assets/resources/Climate_change_policy.pdf`}>Climate Change Policy
                                </a></li>
                        </ul>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default Login