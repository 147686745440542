import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserSection from './UserSection/UserSection'
import  gob from '../../../assets/images/gob.png'
import './header.css'


const Styles = {
  HeadlineText: {
    color: '#000000',
    bg: '#ffffff'
  }
}
const Header = ({ addStyle = "" }) => {
  const navigate = useNavigate()
  const handleHome = () => {
    navigate('/')
  }
  const handleLogo = () => {
    window.location.replace('http://bangladesh.gov.bd');
  }


  return (
    <header /* style={{
      color: Styles.HeadlineText.color,
      background: Styles.HeadlineText.bg
    }} */ className={'header-container ' + addStyle} id='header_'>


      <img className='header-logo hover:cursor-pointer' onClick={handleLogo}
        src={gob} />

      <div className=" header-text-panel " >
        <div className='w-[fit-content] hover:cursor-pointer' onClick={handleHome}>
          <h3 className='header-main-text'> {'Planning Information System (PLIS)'}</h3>
          <h2 className='header-sub-text'>Programming Division, Bangladesh Planning Commission</h2>
        </div>

      </div>
      <p className='w-36 self-start pt-2 text-sm supportedBy'>Funded By:</p>
      <div className='relative w-52 h-16 my-auto text-center bg-white overflow-hidden rounded-xl mr-12 px-1'>
        <a href='https://www.giz.de'>
          <img src='./img/giz.png' className='' />
        </a>
      </div>
      {localStorage.getItem("user") 
       && (<div className='w-8 md:w-40 mr-4 md:mr-2 '>
        <UserSection />
      </div>) }

    </header>
  )
}

export default Header