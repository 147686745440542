import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react"
import { Box } from '@mui/material';
import { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';


const SelectDropDown = ({ className, handler = false, element = [], name, label,
    multiple = false, disabled = false, selected, required = false, dark = false, id }) => {

    const [value, setValue] = useState(!multiple ? "" : [])
    const [names, setNames] = useState([])

    const styles = theme => ({
        select: {
            '&:before': {
                borderColor: '#fff',
            },
            '&:after': {
                borderColor: '#fff',
            }
        },
        icon: {
            fill: '#fff',
        },
    })

    const handleChange = (event) => {
        event.preventDefault()


        setValue(event.target.value)
        handler && handler(name, event.target.value)


    }



    return (<div className=''>
        <Box
            sx={{
                '& > :not(style)': { m: { xs: .5, md: 1 }, width: '25ch' }, display: 'flex', justifyContent: 'center', borderColor: '#fff',
            }}
            noValidate
            autoComplete="off"
        >
            {
                (<FormControl size='small' sx={{ minWidth: '300px' }}  >

                    <InputLabel sx={{ color: dark && '#fff' }} id="demo-simple-select-label" >{label}</InputLabel>
                    <Select
                        classes={className}
                        required={required}
                        disabled={disabled}
                        multiple={multiple}
                        sx={{ "&:hover": { bgcolor: 'transparent' }, textAlign: 'start', color: dark && '#fff' }}
                        labelId="demo-simple-select-label"
                        id={id}
                        name={name}
                        value={disabled ? selected : value ? value : ""}
                        label={label}
                        onChange={handleChange}
                        displayEmpty
                    >

                        {element.length > 0 ? element.map((el, index) => {
                            if (!multiple) {
                                return (<MenuItem sx={{ fontSize: '12px' }} key={index} value={el}>{el}</MenuItem>)
                            }

                        }) : (<MenuItem value={""}>{""}</MenuItem>)}
                    </Select>
                </FormControl>)}

        </Box>

    </div>)
}

export default SelectDropDown