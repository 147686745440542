import React from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
const url ='https://yoast.com/cdn-cgi/image/width=1012%2Cheight=531%2Cfit=cover%2Cf=auto%2Cquality=75%2Conerror=redirect/app/uploads/2016/10/404_error_checking_FI.jpg'
export default function PageNotFound() {
    return (
        <div className="w-full h-[100vh] text-red-500  ">
            <Header addStyle=' sticky top-0' />
            <div className={'bg-cover'} style={{backgroundImage:`url("${url}"`}}>
               {/*  <img src={url} alt="404_image" />
 */}
                <h1 className='text-7xl font-extralight  font-mono'>404</h1>
                <h1>PAGE NOT FOUND</h1>
            </div>

            <Footer />
        </div>
    );
}