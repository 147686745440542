import React, { useEffect, useState } from 'react';
import 'ol/ol.css';
import OlMap from 'ol/Map';
import OlSourceOSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import TileWMS from 'ol/source/TileWMS';
import LayerGroup from 'ol/layer/Group';
import LayerSwitcher from 'ol-layerswitcher';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import SatelliteMap from 'ol/source/XYZ';
import XYZ from 'ol/source/XYZ';
import { VectorLayerDrow } from '../../../shared/MapHelper';
import { GetContext } from '../../../shared/Context';
import { ScaleLine, defaults as defaultControls } from 'ol/control.js';
import { getMapTitle } from '../../../shared/LayerSwitchCaseHelper';

const PublicMap = ({ map_id = "", setMaps }) => {
    const scaleLine = new ScaleLine({ bar: true, text: true, minWidth: 125 });

    const myContext = GetContext()
    //    const [selectedLayers, setSelectedLayers] = useState([]);
    let selectedLayers = [];

    /* *********** Main Map view ********** */
    const viewProps = new OlView({
        center: [90.45607886403209, 23.10099061856948],
        zoom: 7.4,
        projection: 'EPSG:4326',
    });



    /* *********** ALL Layer Source ********** */

    const GoogleMap = new TileLayer({
        title: 'Google',
        type: 'base',
        source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
            //  url: 'https://khms1.googleapis.com/kh?v=152&hl=es-ES&x={x}&y={y}&z={z}'
        })
    });
    const SatelliteMapp = new TileLayer({
        title: 'Satellite',
        type: 'base',

        source: new SatelliteMap({
            // attributions: ['Powered by Esri'],
            // attributionsCollapsible: false,
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 23,
        }),
        visible: false,

    });



    /* *********** Main Map ********** */

    const olmap = new OlMap({
        //   target: null,
        layers: [
            // new LayerGroup({
            //     title: 'Base Map',
            //     name: 'Base',
            //     fold: 'close',
            //     layers: [SatelliteMapp, GoogleMap],
            // }),
            new TileLayer({
                source: new OlSourceOSM(),
            })
        ],
        view: viewProps,
    });

    /* *********** Layer Switcher ********** */
    const layerSwitcher = new LayerSwitcher();
    olmap.addControl(layerSwitcher);



    /* *********** Onclick Event ********** */


    /* Remove Extra Rendered Map */
    const showMap = () => {
        olmap.setTarget('map' + map_id);
        const mapElement = document.getElementById('map' + map_id);

        if (mapElement.childElementCount) {
            while (mapElement.childElementCount > 1) {
                mapElement.removeChild(mapElement.firstElementChild);
            }
        }
    }

    /*Draw Analizer Layer */
    const makeLayers = () => {
        var formateValue = map_id.replace(/_/g, ' ');
        const layer = new TileLayer({
            title: `${formateValue}`.charAt(0).toUpperCase() + `${formateValue}`.slice(1),
            source: new TileWMS({
                params: { LAYERS: `${map_id}`, TILED: true },
                url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
                serverType: 'geoserver',
                crossOrigin: 'anonymous',
                ratio: 1,
            }),
            opacity: 0.5,
        });
        selectedLayers.push(layer)
        const selectedLayerList = new LayerGroup({
            title: `Selected Layer`,
            fold: 'close',
            layers: selectedLayers,
        });
        olmap.addLayer(selectedLayerList);
        olmap.addControl(scaleLine)
    }

    useEffect(() => {
        showMap();
        makeLayers()
    }, []);



    olmap.once('rendercomplete', function () {
        setMaps({ [map_id]: olmap })
    })

    useEffect(() => {
        const analysisResult = myContext.analysisResult;
        if (analysisResult) {
            analysisResult.map((dt) => {
                const x = getMapTitle(map_id) === dt.title;
                if (x && dt.data && dt.data[0].closepointa && dt.data[0].closepointb) {

                    const projectClosePoint = dt.data[0].closepointa.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' });
                    const selectedLayerClosePoint = dt.data[0].closepointb.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' });
                    const data = ({ 'distance': dt.data[0]['distance(km)'].toFixed(2), 'lName': dt.title })
                    const NewLineString = [projectClosePoint, selectedLayerClosePoint];

                    const color = dt.title === 'Hatbazar' ? 'red' : dt.title === 'Roads' ? 'blue' : dt.title === 'Rivers' ? '#AAC4DC' : dt.title === 'BrickField' ? 'red' : 'black';
                    const layerName = dt.title === 'Hatbazar' ? 'Hatbazar' : dt.title === 'Roads' ? 'Road' : dt.title === 'Rivers' ? 'River' : dt.title === 'BrickField' ? 'Brick Field' : '';
                    VectorLayerDrow(olmap, NewLineString, data.distance, layerName, layerName, color);
                }
            });
        }
    }, [myContext.analysisResult])

    useEffect(() => {
        const layerType = myContext.projectData.type;
        const layerId = myContext.projectData.id;
        const newCenter = [myContext.projectData.centerlon, myContext.projectData.centerlat]
        const analizerLayer = new TileLayer({
            source: new TileWMS({
                url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
                params: { LAYERS: `dpp_projects_${layerType}`, CQL_FILTER: `[id IN ('${layerId}')]`, TILED: true },
                //    params: { LAYERS: "dpp_projects_polygon", TILED: true, CQL_FILTER: "[id IN ('1')]" },
                serverType: 'geoserver',
                crossOrigin: 'anonymous',
            }),
            opacity: 0.5
        });

        const extent = myContext.projectData.bbox.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' }).join(',').split(',');

        olmap.addLayer(analizerLayer);
        if (layerType !== 'point') {
            olmap.getView().fit(extent, { padding: [600, 600, 600, 600], constrainResolution: false });
            olmap.getView().fit(extent);
        } else {
            viewProps.setCenter(newCenter);
            viewProps.setZoom(17);
        }
    }, []);

    return (

        <>
            <div id={`map${map_id}`} className={`map${map_id}`} style={{ width: '100%', height: '50vh' }} >
            </div>
            <div id="info">&nbsp;</div>
        </>
    );
}



export default PublicMap;
