import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";
import ProfileEditModal from "./ProfileEditModal";
import noImage from '../../assets/images/user.png'
import axios from "axios";
import UploadIcon from '@mui/icons-material/Upload';
import { isLoggedIn } from "../../utils/util";
import showToast from "../../utils/Toast";


const Profile = () => {
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem('user')) || {};
    const [data, setData] = useState()
    const [visible, setVisible] = useState(false)
    const [selectedImage, setSelectedImage] = useState()
    const base_url = process.env.REACT_APP_BACKEND_IP_ASSETS
    const base = process.env.REACT_APP_BACKEND_IP


    const handleGoBack = () => {
        navigate(-1);
    };
    const getUserType = (type) => {
        return type === 0 ? 'Admin User' : type === 1 ? 'Planning Commission User' : 'Agency User'
    }
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/')
        }
    }, [])


    useEffect(() => {
        if (userData) {
            setData({
                ID: userData.userId, ' User Name': userData.userName, 'User Email': userData.userEmail,
                'User Type': getUserType(userData.userType), 'Agency Name': userData.agencyName,
                'Ministry Name': userData.ministryName, 'Sector Name': userData.sectorName,
                'Division Name': userData.sectorDivName,
            })
        }

    }, [])


    const handleImageChange = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader();
        reader.onload = function (e) {
            const previewImage = document.getElementById("profileImg")
            previewImage.src = e.target.result;
        };
        reader.readAsDataURL(file);
        const filename = `${userData.userName.split(" ")[0]}_${userData.userId}.${file.name.split(".")[1]}`

        const renamedFile = new File([file], filename,
            { type: `image/${file.name.split(".")[1]}` });
        setSelectedImage(renamedFile)
    }

    const handleImageNotFound = () => {
        const noImageFound = document.getElementById('profileImg')
        noImageFound.src = noImage
    }

    const handleUpload = (e) => {
        const file = selectedImage
        if (file && file.type === "image/jpg" || "image/jpeg" || 'iamge/png') {

            const formData = new FormData();
            formData.append("file", file);


            axios.put(`${base}/upload/profileimg`,
                formData,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, })
                .then(res => {
                    localStorage.setItem('user', JSON.stringify({ ...userData, profileImg: res.data }))
                    setTimeout(() => {
                        window.location.reload()
                    }, 800);
                    showToast({ message: 'Profile picture Changed' , type: 'success' })

                })
                .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                    console.log(e);
                })
        }
    }



    return (
        <div className="mx-auto w-full h-[100vh]  bg-gray-100 overflow-hidden">
            <Header />
            <div className="h-full mt-4 w-[80%] lg:w-[45%] mx-auto">
                <div className="bg-white rounded-md shadow-md p-8">
                    <div className="grid grid-cols-12  mb-2 px-8">
                        <div className="my-auto flex gap-2 col-span-3"> <div className="w-28" >
                            <img
                                onError={handleImageNotFound}
                                id="profileImg"
                                src={`${base_url}/assets/profileimg/${userData.profileImg}`}
                                className="bg-opacity-100 rounded-xl w-28 h-32"
                            />
                            <input type="file"
                                onChange={handleImageChange} id="imageInput"
                                accept="image/jpeg, image/png" className="hidden" />
                            <label htmlFor="imageInput" className="rounded-xl mt-1 hover:shadow-lg">
                                <span
                                    className="text-sm hover:scale-75  px-2
                                     hover:cursor-pointer bg-gray-100
                                      shadow-md mt- border border-black
                                       font-semibold">Change Photo</span>
                            </label>

                        </div>
                            {selectedImage && <div
                                onClick={handleUpload}

                                className="bg-gray-50 h-8 border my-auto cursor-pointer hover:scale-95 active:scale-110">
                                <UploadIcon />
                            </div>}

                        </div>
                        <h1 className="my-auto col-start-6 text-2xl font-bold pb-4 col-span-3">Profile Details</h1>

                        {/*   <div
                            onClick={(e) => {
                                setVisible(true)
                            }}

                            className="my-auto w-8 col-start-12 text-end  col-span-1 rounded-md hover:scale-105 hover:bg-[#a2a2a2] cursor-pointer border-2 border-black">
                     
                                <EditIcon />
                            

                        </div> */}
                    </div>

                    <div className="grid grid-cols-2 gap-4 px-8 text-start ">

                        {data && Object.entries(data).map((el, index) => {
                            if (el[1]) return (<div key={el[1]} className={`${(index % 2 === 0) && (index == Object.keys(data).length - 1) ? ' col-span-2 ' : ' col-span-1'}`}>
                                <p className='text-gray-600 px-2 mb-1' >{el[0]}</p>
                                <p className="font-semibold border rounded-md py-1 bg-gray-100 border-2-black px-2">{el[1] || 'N/A'}</p>
                            </div>)
                        })}

                    </div>
                </div>
            </div>
            {visible && <ProfileEditModal data={data} onClose={() => { setVisible(false) }} />}
            <Footer />
        </div>
    );
};

export default Profile;
