import React from 'react'
import '../../css/footer.css'
import tiller from '../../assets/images/tiller.png'
const Footer = () => {
  return (
    <footer className='footer-container ' id='footer_'>
      <div className='footer-dev-container'>
        <p className='footer-dev-container-span1 '>Concept, Design & Developed by</p>
        <a className='footer-dev-container-span2 ' href="https://tiller.com.bd/" >
          <span className='ml-2 font-semibold flex items-center'>
            <p>Tiller</p>
            <img className=' ml-2 w-12' alt='logo' src={tiller} />
          </span></a>
      </div>
     { <div className='footer-copyright'>Copyright ©️{new Date().getFullYear()} Bangladesh Planning Commission. All rights reserved.</div>}
    </footer>
  )
}

export default Footer