import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature"; 


export const VectorLayerDrow = (mapName, coordinates, distance, dLayerName, tLayerName, color) => {

    function styleFunction() {
        return [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 4,
                    lineDash: [10, 10]
                }),
                text: new Text({
                    font: '12px Calibri',
                    text: 'Nearest' + ' ' + tLayerName + ' ' + distance + ' ' + 'km',
                    //    text: `${tLayerName}`,
                    placement: 'line',
                    fill: new Fill({
                        color: '#000'
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 5
                    })
                }),
            })
        ];
    }

    dLayerName = new VectorLayer({
        source: new VectorSource({
            features: [
                new Feature({
                    geometry: new LineString(coordinates),
                    name: "Line"
                })
            ]
        }),
        style: styleFunction
    });

    mapName.addLayer(dLayerName)

}