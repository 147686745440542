import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { InfoOutlined, LayersOutlined, MenuOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { GetContext } from './Context';


const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});
const CustomizedList = ({ setter, layerSelector, selectedLayers, setSelectedLayers }) => {
    const [open, setOpen] = useState({});
    const [layerItems, setLayerItems] = useState()
    const myContext = GetContext()
    const base_url = process.env.REACT_APP_BACKEND_IP
    const [checkedList, setCheckedList] = useState([])


   /*  useEffect(() => {
        if (myContext.selectedLayers) {
        setCheckedList( Object.entries(myContext.selectedLayers).map(el => {
              return {name:el[1].layer, title :el[1].title}
            }))

            setSelectedLayers(myContext.selectedLayers)
        };

        

    }, []) */

    useEffect(() => {
        const url = `${base_url}/layerlist`
        let groupData = {}
        axios.get(url, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {
                res.data.map(el => {
                    groupData[el.group] = groupData[el.group] ? [...groupData[el.group], el] : [el]
                })
                setLayerItems(groupData);
            })

    }, [])



    const handleItemCheck = (action, element) => {
        if (action) {
            setCheckedList([...checkedList, { name: element.layer, title: element.title }])
            setter([...checkedList, { name: element.layer, title: element.title }])
        }
        else {
            setCheckedList(checkedList.filter(dt => dt.name !== element.layer))
            setter(checkedList.filter(dt => dt.name !== element.layer))
        }

        layerSelector({ visibility: action, element })
        if (action) {
            setSelectedLayers({ ...selectedLayers, [element.title]: element })
            myContext.setSelectedLayers({ ...selectedLayers, [element.title]: element })
        }
        else {
            const temp = { ...selectedLayers }
            delete temp[element.title]
            setSelectedLayers(temp)
            myContext.setSelectedLayers(temp)
        }
    }



    const handleCollapseClick = (el) => {

        const targetElement = document.getElementById(el)
        if (targetElement) {
            Object.entries(open).map(e => {
                if (e[1])
                    document.getElementById(e[0]).style.display = 'none'
            })
            targetElement.style.display = open[el] ? 'none' : 'block'
            setOpen({ [el]: !open[el] })

        }
    }


    useEffect(() => {

    }, [selectedLayers])


    return (
        <Box sx={{ height: '100%' }} className='bg-[#ffffff]'>


            <ThemeProvider
                theme={createTheme({
                    components: {
                        MuiListItemButton: {
                            defaultProps: {
                                disableTouchRipple: true,
                            },
                        },
                    },
                    palette: {
                        mode: 'dark',
                        primary: { main: 'rgb(0, 0, 0)' },
                        background: { paper: 'rgb(255, 255, 255)' },
                    },
                })}
            >
                <Paper className='layer-container' id='layers-container' elevation={0} sx={{ minWidth: '100%' }}>
                    <FireNav component="nav" disablePadding>
                        <Divider />
                        <ListItem component="div" disablePadding >
                            <ListItemButton sx={{ height: 56, backgroundColor: '#ffffff', }}>
                                <ListItemIcon>
                                    <MenuOutlined onClick={(e) => {
                                        const x = document.getElementsByClassName("layers-container")
                                        x[0].style.display = 'block'
                                    }} color='primary' className='mr-2' />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ cursor: 'pointer', 'fontWeight': 600, fontSize: '16px', textAlign: 'start', }}
                                    primary="ALL LAYERS"
                                    primaryTypographyProps={{
                                        color: '#000000',

                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <Box
                            sx={{
                                bgcolor: open ? 'rgba(255, 255, 255)' : null,
                                color: '#000000',
                                pb: open ? 20 : 0, 'overflowY': 'scroll',
                                height: '85vh',
                            }}
                        >
                            {layerItems && Object.entries(layerItems).map(el => {

                                return (<div key={el[0]}>
                                    <div className={`${el[0]} `} onClick={(e) => handleCollapseClick(el[0])}>
                                        <ListItemButton
                                            alignItems="flex-start"
                                            // sx={{ p:0,m:0,pl: 4 }}
                                            sx={{
                                                px: 3,
                                                pt: 2,
                                                pb: '-1',
                                                // pt: 2.5,                                               
                                                '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 1 } },
                                            }}
                                        >
                                            <ListItemIcon sx={{ 'my': 0, color: '#000000' }}>
                                                <LayersOutlined color='#000000' />
                                            </ListItemIcon>
                                            <ListItemText primary={el[0]}
                                                primaryTypographyProps={{
                                                    fontSize: 18,
                                                    fontWeight: 'medium',
                                                    lineHeight: '20px',
                                                }}

                                                secondary={el[1].map((n, index) => `${n.title}${index < el[1].length - 1 ? "," : "."} `)}
                                                secondaryTypographyProps={{
                                                    noWrap: false,
                                                    fontSize: 12,
                                                    lineHeight: '16px',
                                                    display: open[el[0]] ? 'none' : 'block',
                                                    color: 'rgba(0,0,0)',

                                                }}
                                                sx={{ my: 0, }}
                                            />
                                            <KeyboardArrowDown sx={{
                                                mr: -1,
                                                opacity: 0,
                                                transform: open[el[0]] ? 'rotate(-180deg)' : 'rotate(0)',
                                                transition: '0.6s',
                                            }}
                                            />
                                        </ListItemButton>
                                    </div>
                                    <div id={el[0]} >
                                        {open[el[0]] && el[1].map(e => {
                                            {
                                                const x = checkedList.find((chk) => {
                                                    return e.layer == chk.name
                                                });
                                            }
                                            return (<ListItemButton key={e.title} sx={{ p: 0, m: 0, pl: 4 }}>
                                                <Checkbox
                                                    checked={checkedList.find((chk) => {
                                                        return e.layer === chk.name
                                                    }) ? true : false}
                                                    onChange={(event) => {

                                                        handleItemCheck(event.target.checked, { ...e })
                                                    }}
                                                    aria-label='Checkbox'
                                                    labelstyle={{ color: '#000000' }}
                                                    iconstyle={{ fill: '#000000' }}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#000000' }, }}
                                                />
                                                <div className='flex items-center gap-1'>
                                                    <ListItemText
                                                        primary={e.title}
                                                        primaryTypographyProps={{
                                                            fontSize: 14,
                                                            fontWeight: 'medium',
                                                            color: 'rgba(0,0,0)',
                                                        }}
                                                        onClick={(e) => {

                                                        }} />
                                                    <InfoOutlined onClick={() => {
                                                        alert(`Group: ${e.group}\nTitle: ${e.title}\nName: ${e.layer}\nVisiblity: ${e.status}`)
                                                    }} />
                                                </div>
                                            </ListItemButton>
                                            )
                                        })}
                                    </div>
                                </div>)
                            })}

                        </Box>
                    </FireNav>
                </Paper>
            </ThemeProvider>
        </Box>
    );
}

export default CustomizedList