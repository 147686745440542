import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const zValue= {zIndex:'tooltip'}

const Pagination = ({ data, setPaginatedData, }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState()

    const onPageChange = (action) => {
        if (action === 'next') {
            setCurrentPage(currentPage + 1)
        }
        else if (action === 'prev') {
            setCurrentPage(currentPage - 1)
        }
    };

    const onFirstPage = () => {
        setCurrentPage(1)
    }

    const onLastPage = () => {
        setCurrentPage(totalPages)
    }
    useEffect(() => {
        setCurrentPage(1);
        setTotalPages(Math.ceil(data?.length / itemsPerPage))
        data && setPaginatedData(data.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage))
    }, [data])

    useEffect(() => {
        setTotalPages(Math.ceil(data?.length / itemsPerPage))
        data && setPaginatedData(data.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage))
    }, [itemsPerPage, currentPage])

    return (
        <div className='w-full'>
            <div className=" w-[80%] mx-auto flex justify-center gap-1 items-center">
                <IconButton disabled={currentPage === 1} onClick={onFirstPage} >
                    <FirstPageIcon sx={{zIndex:'-4'}}/>
                </IconButton>
                <IconButton disabled={currentPage === 1} onClick={() => onPageChange('prev')}>
                    <NavigateBeforeIcon  sx={{zIndex:'-4'}}/>
                </IconButton>
                <span>Page {currentPage} of {totalPages}</span>
                <IconButton disabled={ !totalPages ||currentPage === totalPages} onClick={() => onPageChange('next')}>
                    <NavigateNextIcon  sx={{zIndex:'-4'}}/>
                </IconButton>
                <IconButton disabled={ !totalPages ||currentPage === totalPages} onClick={onLastPage}>
                    <LastPageIcon  sx={{zIndex:'-4'}}/>
                </IconButton>
                <div>
                    <select
                        onChange={(e) => { setItemsPerPage(e.target.value || 10) }}
                        defaultValue={10}
                        className='text-black text-center border border-1' >
                        <option value={5} >5</option>
                        <option value={10} >10</option>
                        <option value={20} >20</option>
                        <option value={50} >50</option>
                    </select> Items
                </div>
            </div>
        </div>
    );
};

export default Pagination;