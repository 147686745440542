import { Divider } from '@mui/material'
import React, { useEffect } from 'react'

const Legend = ({ layers }) => {
    if (!layers) {

    }

    return (<div className='h-full'>
        <h2 className=' h-[2rem] text-xl font-semibold mb-1 bg-[#ffffff] text-[#000]  rounded-tl-md  text-center'>Legends</h2>
        <Divider variant='middle' />
        <div className=' pb-10 overflow-y-scroll scrollbar-hide h-[85vh]  text-[#000] flex flex-col items-start gap-2 bg-[#ffffff] ' id='legend'>
            {layers && layers.map(el => {
                return (<div key={el.title} className='w-full'>
                    <div>
                        <Divider  variant='fullWidth'>
                            <h3 className="text-start text-[#000] font-semibold tracking-widest bg-[#ffffff] pl-2 pb-1">
                                {el.title}
                            </h3>
                        </Divider>
                        <div>
                            <img alt='legend' src={`http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=${el.name}&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000000;fontSize:10;bgColor:0xFFFFFF;dpi:180`} />
                        </div>
                    </div>
                    {/* <Divider variant='middle' /> */}
                </div>)
            })}

        </div>
    </div>
    )
}
export default Legend