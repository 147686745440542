import { PDFDocument } from "pdf-lib"
import jsPDF from "jspdf"
import html2pdf from "html2pdf.js"

const capitalizeOnlyFirst = (text) => {
  if (!text || text.trim() === "") {
    return 'N/A'
  }
  return text.split(" ").map(el => {
    return el[0]?.toUpperCase() + el?.slice(1)?.toLowerCase()
  }).join(" ")
}


async function mergePdfs(data, name, setter) {
  const mergedPdf = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = data.map((element) => {
    if (typeof element.then === 'function') {
      return element.then((res) => createInnerPromise(res.output('arraybuffer')));
    } else {
      const arrayBuffer = element.output('arraybuffer');
      return createInnerPromise(arrayBuffer)
    }
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  resultOuterPromise.forEach((pageArray) => {
    pageArray.forEach((page) => {
      mergedPdf.addPage(page);
    });

  });

  const file = await mergedPdf.save();
  const mergedPdfBlob = new Blob([file], { type: 'application/pdf' });
  const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);
  const link = document.createElement('a');
  link.href = mergedPdfUrl;
  link.download = `${name}`;


  link.click();
  return mergedPdfBlob
}



const getMapTitle = (title) => {
  const parts = title.split("_")
  return parts.map(el => el.split("")[0].toUpperCase() + el.slice(1, el.length)).join(" ");
}

const addText = ({
  docEl,
  text,
  posX = 0,
  posY = 0,
  font = "times",
  size = 10,
  style = "normal",
  color = "#ffffff",
  underline = false
}) => {
  if (!(docEl && text)) {
    return 0;
  }


  docEl.setFont(font);
  docEl.setFontSize(size);
  docEl.setTextColor(color);

  if (!underline) docEl.text(text, posX, posY);

  return docEl;
};

const addImage = ({
  docEl,
  src,
  posX = 0,
  posY = 0,
  width = 10,
  height = 10,
  format = "png"
}) => {
  if (!(docEl && src)) {
    return 0;
  }
  const imgData = new Image();
  imgData.src = src;
  docEl.addImage(imgData, format, posX, posY, width, height);
  return 1;
};

const addPageNum = (doc) => {
  var totalPages = doc.internal.getNumberOfPages();
  for (var i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    addText({
      docEl: doc,
      text: i + " / " + totalPages,
      posX: doc.internal.pageSize.width - 7,
      posY: doc.internal.pageSize.height - 4,
      color: "#ffffff"
    });
  }
};


const getYPos = (text, doc) => {
  const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor
  const splittedText = doc.splitTextToSize(text, 50)
  const lines = splittedText.length
  const blockHeight = lines * lineHeight + 2
  return blockHeight + 5
}

function isObjEmpty(obj) {
  return (Object.keys(obj).length === 0);
}

const getPDFfromHTMLElement2 = async (element_name) => {
  const content = document.getElementById(element_name);

  if (!content) {
    return false;
  }

  const options = {
    margin: 10,
    filename: 'printed_pdf.pdf',
    image: { type: 'jpeg', quality: .99 },
    html2canvas: { scale: 4, allowTaint: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'l' },
  };

  const pdf = await html2pdf()//.from(content).set(options).outputPdf();

  const sections = content.querySelectorAll('.section2brk'); // Replace '.section' with your section class or selector
  const pageHeight = 1024//pdf.internal.pageSize.getHeight();

  let currentPage = 1;
  let currentY = 0; // Tracks the current Y position on the page


  for (const section of sections) {
    const sectionHeight = section.getBoundingClientRect().height;

    ;
    // Check if the section fits within the remaining space on the current page
    if (currentY + sectionHeight > pageHeight) {
      // Start the section on the next page
      pdf.addPage();
      currentPage++;
      currentY = 10;
    }

    pdf.from(section).set(options)
      
    currentY += sectionHeight;
  }

  pdf.save();
};




const getPDFfromHTMLElement3 = async (element_name) => {

  const content = document.getElementById(element_name).cloneNode(true);
  const footer = document.getElementById('footer_').cloneNode(true);
  const header = document.createElement('div');
  "flex items-center gap-4 font-bold border-b-2 border-black w-full pb-4 text-md".split(" ").map(el => {
    header.classList.add(el)
  })

  const title = document.createElement('p')
  title.innerText = 'Planning Information System (PLIS)'
  title.classList.add('text-3xl')
  const sub = document.createElement('p')
  sub.innerText = 'Programming Division, Bangladesh Planning Commission'
  sub.classList.add('text-md')

  const img = document.createElement('img')
  img.src = './img/gob.png'

  "h-16 w-16".split(" ").map(el => {
    img.classList.add(el)
  })

  const para = document.createElement('div');
  // para.classList.add()


  para.appendChild(title)
  para.appendChild(sub)
  header.appendChild(img)
  header.appendChild(para)

  footer.classList.add('text-black')
  footer.classList.add('bg-red-500')


  if (!content) {
    return false
  }
  if (content && header && footer) {
    const combinedDiv = document.createElement('div');
    // Clone the div to avoid modifying the original
    combinedDiv.appendChild(header); // Clone the div to avoid modifying the original
    combinedDiv.appendChild(content);
   
    combinedDiv.appendChild(footer);


    const options = {
      margin: 10,
      filename: 'printed_pdf.pdf',
      image: { type: 'jpeg', quality: .99 },
      html2canvas: { scale: 4, allowTaint: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
    };


    return html2pdf().set(options).from(combinedDiv).toPdf().get("pdf")
  }



}




const getPDFfromHTMLElement = (element_name,orientation) => {

  const content = document.getElementById(element_name);
  if (!content) {
    return false
  }

  const options = {
    margin: 10,
    filename: 'printed_pdf.pdf',
    image: { type: 'jpeg', quality: .99 },
    html2canvas: { scale: 4, allowTaint: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: orientation?orientation:'l' },
  };

  return html2pdf().set(options).from(content).toPdf().get("pdf")


}

const getLegendTitle = (map_id) => {

  switch (map_id) {
    case 'waterbodies_bd':
      return 'WaterBody'

    case 'soil_moisture_bd':
      return 'SoilMoisture'

    case 'salinity_soil_bd':
      return 'SoilSalinity'

    case 'roads_line':
      return 'Roads'

    case 'river_bd':
      return 'Rivers'

    case 'hatbazar_bd':
      return 'Hatbazar'

  }
}


const isLoggedIn = () => {
  return JSON.parse(localStorage.getItem('user'))
}




export {
  capitalizeOnlyFirst, mergePdfs, isObjEmpty,
  addText, addImage, addPageNum, getYPos, getMapTitle,
  getLegendTitle, getPDFfromHTMLElement, isLoggedIn, getPDFfromHTMLElement2, getPDFfromHTMLElement3

}