import React, { useEffect, useState } from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import './index.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MuiFileInput } from 'mui-file-input';
import FileInput from '../UploadSpatialFile/FileInput';
import axios from 'axios';
import { capitalizeOnlyFirst, isLoggedIn } from '../../utils/util';
import { GetContext } from '../shared/Context';
import showToast from '../../utils/Toast';

const AddProject = () => {
  const [data, setData] = useState({});
  // const [file, setFile] = useState(null);
  const [ministryList, setMinistryList] = useState([])
  const [agencyList, setAgencyList] = useState()
  const [districtList, setDistrictList] = useState([])
  const [sectorList, setSectorList] = useState([])
  const [error, setError] = useState()
  const base_url = process.env.REACT_APP_BACKEND_IP
  const userData = JSON.parse(localStorage.getItem('user'))
  const geomList = ['Line', 'Point', 'Polygon']

  const navigate = useNavigate();



  useEffect(() => {

    if (!isLoggedIn()) {
      navigate('/')
    }
  }, [])



  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const [message, setMessage] = useState("")
  const handleBack = () => {
    navigate('/allprojects');
  };


  const handleSubmit = (e) => {

    e.preventDefault()

    if (data.project_name) {
      if (data.sector_id) {
        if (data.doe_cat) {
          setError("")
          const geomType = data.geometry_type ? data.geometry_type : 'nogeom'
          const formData = {
            project_name: data.project_name,
            district: data.project_district,
            sector_id: data.sector_id,
            eia_category: data.doe_cat

          }


          axios.post(`${base_url}/create/dpp?geomtype=${geomType}`,
            formData, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          })
            .then(res => {

              setMessage("Successfully Added Project : " + data.project_name)
              showToast({ message: "Successfully Added Project : " + data.project_name })
              setTimeout(() => {
                navigate('/allprojects');
              }, 1000);
            })
            .catch(e => {
              if (e.response.data == "jwt expired") {
                localStorage.removeItem("user")
                localStorage.removeItem("token")
                localStorage.removeItem("password")
                window.location.href = process.env.REACT_APP_FE_HOME;
              }
              console.error(e);
              setError(e.response?.data?.message || e.code)
              setMessage(e.response.data.message || e.code)
            })
        } else {
          setError("Specify DoE Category.")
        }

      } else {
        setError("Sector can\'t be empty!")
      }
    }
    else {
      setError("Project Name can\'t be empty!")
    }




  };


  useEffect(() => {
    /* GET MINISTRY */
    axios.get(`${base_url}/options/ministry`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(res => {
        setMinistryList(res.data.ministryList);
      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })
    /* GET LOCATION */
    axios.get(`${base_url}/options/district`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(res => {

        setDistrictList(res.data.districtList.map(el => {

          return { 'dist_name': capitalizeOnlyFirst(el.dist_name) }
        }));
      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })


    /* GET SECTORS */

    axios.get(`${base_url}/options/sectors`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(res => {

        setSectorList(res.data.sectorList.map(el => {
          return { sector_id: el.sector_id, sector_name: el.sector_name_en }
        }));
      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })
  }, [])


  useEffect(() => {
    const ministry_id = ministryList.filter(el =>
      el.ministry_name === data.ministry)[0]?.ministry_id
    axios.get(`${base_url}/options/agency${ministry_id ? `?ministryid=${ministry_id}` : ""}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(res => {

        setAgencyList(res.data.agencyList);

      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })
  }, [data.ministry])



  return (
    <div className="h-[100vh] w-[100%]">
      <Header />
      <main className="mt-10">
        <h3 className="title">Add New Project</h3>
        <div className="container">
          <div className="field-container">
            <label className="field-label" htmlFor="project_name">
              Project Name
            </label>
            <input
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="field-input"
              type="text"
              name="project_name"
              id="project_name"
            />
          </div>
          <div className="field-container-double">
            {userData?.ministryName ? (<div className="field-container">
              <label className="field-label" htmlFor="ministry">
                Ministry
              </label>
              <input
                defaultValue={userData?.ministryName}
                disabled
                type='text'
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="field-input"
                name="ministry"
                id="ministry"
              />


            </div>) : <></>}

            {userData?.agencyName ? (<div className="field-container">
              <label className="field-label" htmlFor="agency">
                Agency
              </label>
              {agencyList && <input
                type='text'
                defaultValue={userData?.agencyName}
                disabled
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="field-input "
                name="agency"
                id="agency"
              />
              }
            </div>) : <></>}




            <div className="field-container">
              <label className="field-label" htmlFor="sector">
                DPP Sector
              </label>
              <select
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="field-input"
                name="sector_id"
                id="sector"
              >
                <option value="">Select Sector</option>
                {sectorList && sectorList.map((el, index) => {
                  return (<option
                    key={`el${index}`}
                    value={el.sector_id}>{el.sector_name}</option>)
                })}
              </select>
            </div>
          </div>
          <div className="field-container-double">
            <div className="field-container">
              <label className="field-label" htmlFor="project_district">
                Project District
              </label>
              <select
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="field-input"
                name="project_district"
                id="project_district"
              >
                <option value="">Select District</option>
                {districtList && districtList.map((el, index) => {
                  return (<option key={`el.dist_name_${index}`} value={el.dist_name}>{el.dist_name}</option>)
                })}

              </select>
            </div>

            <div className="field-container">
              <label className="field-label" htmlFor="geometry_type">
                DoE Category
              </label>
              <select
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="field-input"
                name="doe_cat"
                id="doe_cat"
              >
                <option value="">Select</option>
                {[{ cat_name: 'Green', cat_id: 1 },
                { cat_name: 'Orange A', cat_id: 2 },
                { cat_name: 'Orange B', cat_id: 3 },
                { cat_name: 'Red', cat_id: 4 }].map((el, index) => {
                  return (<option key={`${el.cat_name}_${index}`} value={el.cat_id}>{el.cat_name}</option>)
                })}

              </select>
            </div>

            <div className="field-container">
              <label className="field-label" htmlFor="geometry_type">
                Geometry Type
              </label>
              <select
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="field-input"
                name="geometry_type"
                id="geometry_type"
              >
                <option value="nogeom">Select Geom Type</option>
                {geomList && geomList.map((el, index) => {
                  return (<option key={`el${index}`} value={el.toLowerCase()}>{el}</option>)
                })}

              </select>
            </div>

          </div>

          <div className='button-panel text-red-500'>
            <p>{error}</p>
          </div>

          <div className="button-panel">
            <Button variant="contained" onClick={handleBack}>
              Back
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>

          </div>
          <p className='text-black'>{message}</p>
        </div>

      </main>
      <Footer />
    </div>
  );
};

export default AddProject;
