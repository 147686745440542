import { useEffect, useState } from 'react';
import OlMap from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import XYZ from 'ol/source/XYZ';
import Footer from '../../shared/Footer'
import Header from '../../shared/Header'
import axios from 'axios';
import { GetContext } from '../../shared/Context';
import { Link } from 'react-router-dom';

import TileWMS from 'ol/source/TileWMS';

const GoogleMapSrc = new XYZ({
    url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
    //  url: 'https://khms1.googleapis.com/kh?v=152&hl=es-ES&x={x}&y={y}&z={z}'
});
const GoogleMap = new TileLayer({
    title: 'google',
    type: 'base',
    source: GoogleMapSrc
    //    visible: false
});

const Landing = () => {

    const [data, setData] = useState({})
    const [map, setMap] = useState()
    const base_url = process.env.REACT_APP_BACKEND_IP
    const myContext = GetContext()
    const viewProps = new OlView({
        center: [90.45607886403209, 23.10099061856948],
        zoom: 7.4,
        projection: 'EPSG:4326',
    });

    const showMap = (map) => {
        if (map != null || map != undefined) {
            const mapElement = document.getElementById('map');
            if (mapElement.childElementCount) {
                while (mapElement.childElementCount > 1) {
                    mapElement.removeChild(mapElement.firstElementChild);
                }
            }
        }
    }
    useEffect(() => {

        const olmap = new OlMap({

            layers: [
                GoogleMap,
            ],
            view: viewProps,
        });
        olmap.setTarget('map')
        showMap(olmap);
        setMap(olmap)
    }, []);

    const capitalizeFirstLetter = (str) => {
        if (str)
            return str[0].toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        const url = `${base_url}/dppProjectList/all?status=${'all'}`
        axios.get(url, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
            .then(res => {
                let count = 0
           
                const fetcheddata = []
                Object.entries(res.data).map((dt) => {
                    count += dt[1].length
                    dt[1].map((d) => {
                        fetcheddata.push({ ...(d), 'key': d.id + "-" + d.district, 'type': dt[0], 'district': capitalizeFirstLetter(d.district), 'idd': fetcheddata.length + 1, 'pstatus': capitalizeFirstLetter(d.status)?.replace(/\s/g, '') });
                        return null;
                    })
                    return null;
                })
                myContext.setProjectData(fetcheddata[0]);

                setData(fetcheddata[0])
            })
    }, [])


    useEffect(() => {

        const layerType = data.type;
        const layerId = data.id;
        const newCenter = [data.centerlon, data.centerlat];

        /********Create Layer from selected project and show on map******* */
        if (Object.keys(data).length && map) {
            const analizerLayer = new TileLayer({
                source: new TileWMS({
                    url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
                    params: { LAYERS: `dpp_projects_${layerType}`, CQL_FILTER: `[id IN ('${layerId}')]`, TILED: true },
                    serverType: 'geoserver',
                    crossOrigin: 'anonymous',
                }),
            });
            const extent = data.bbox.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' }).join(',').split(',');
            if (map != null || map != undefined) {
                map.addLayer(analizerLayer);
                if (layerType !== 'point') {
                    map.getView().fit(extent, { padding: [20, 20, 20, 20], constrainResolution: false });
                } else {
                    viewProps.setCenter(newCenter);
                    viewProps.setZoom(19);
                }
            }
        }

        // Get Basic Project data from selected project and store it for showing on analysis result page
        if (!data) {
            axios.get(`${base_url}/analysis/basicinfo/dpp_projects_${layerType}/${layerId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
                .then((json) => {
      
                    myContext.setBasicProjectData(json.data[0])
                });
        }
    }, [data, map])

    return (
        <div style={{ width: '100%' }} className=''>
            <Header />
            <div className='my-24 w-[70%] mx-auto'>
                <div className='flex h-[100%] w-[100%] justify-center items-center gap-4 px-12'>
                    <div style={{ width: '100%', height: '50vh' }} className=' relative border-[#13183B] border-b-2 border-2 rounded-xl'>
                        <div className='bg-[#13183B] text-white py-[.75rem] text-lg rounded-t-xl'>
                            <h3 className='font-bold'>Project Details</h3>
                        </div>
                        {data ? <div className='text-start text-lg  px-4 py-4 '>
                            <h3 className='mb-1'><strong>Project Name: </strong> {data.project_name || 'N/A'}</h3>
                            <h3 className='mb-1'><strong>Authority: </strong> {data.authority || 'N/A'}</h3>
                            <h3 className='mb-1'><strong>Division: </strong> {data.division || 'N/A'}</h3>
                            <h3 className='mb-1'><strong>District: </strong> {data.district || 'N/A'}</h3>
                            <h3 className='mb-1'><strong>Upazila: </strong> {data.upazila || 'N/A'}</h3>
                            <h3 className='mb-1'><strong>Mouza : </strong> {data.mouza || 'N/A'}</h3>
                            <h3 className='mb-1'><strong>Submission Date : </strong>{data.submission_date || 'N/A'}</h3>
                        </div> : <p>NO INFO</p>}
                        <div className=' w-[60%]  absolute bottom-1 mx-[18%] mt-2  rounded-xl flex justify-around items-center gap-4 bg-[#13183B] h-12'>
                            {1/* Condition to go in there */ ? (<Link to='/map' className=' px-4 rounded-md text-white border-2 border-white hover:cursor-pointer hover:bg-[#afaaaa]'>SPATIAL ANALYSIS</Link>) :
                                (<div>
                                    <button to='/map' className=' px-4 rounded-md text-white border-2
                                     border-white hover:cursor-pointer hover:bg-[#afaaaa]'>Upload Spatial Data</button>
                                    <p className='text-red-500 text-xs'>*upload some type of data </p>
                                </div>)}
                           
                        </div>
                    </div>
                    <div className='overflow-hidden border-[#13183B] border-b-2 border-2 rounded-xl' id="map" style={{ width: '100%', height: '50vh', }} >
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Landing