import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import Header from '../shared/Header'
import { Button } from '@mui/material'
import { GetContext } from '../shared/Context';



const useStyles = makeStyles((theme) => ({
  dropField: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
  },
  dragOver: {
    backgroundColor: theme.palette.primary.light,
  },
  fileInput: {
    display: 'none',
  },
}));
const FileInput = ({ file, setFile }) => {
  const navigate = useNavigate()
  const myContext = GetContext()
  const { id, project_name, district, authority, type, submission_date } = myContext.projectData || {}

  const [dragging, setDragging] = useState(false);
  const classes = useStyles();
  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];

    if (file.name.endsWith('.kml')) {
      setFile(file);
    } else {
      alert('Please select a .kml file');
    }
  };


  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };


  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };


  const handleFileInput = (event) => {
    const file = event.target.files[0];

    if (file.name.endsWith('.kml')) {
      setFile(file);
    } else {
      alert('Please select a .kml file');
    }
  };



  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        document.getElementById('fileInput').click()
      }}
      className={`${classes.dropField} ${dragging && classes.dragOver}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <input
        type="file"
        accept=".kml"
        className={classes.fileInput}
        onChange={handleFileInput}
        id="fileInput"
      />
      {file ? (
        <Typography variant="body1">
          <p>Selected File Name: {file.name}</p>
          <p>Selected File Size: {file.size<1048576?`${(file.size/1024).toFixed(2)} kB`:`${(file.size/1024).toFixed(2)} MB`} </p>
        </Typography>
      ) : (
        <>
          <CloudUploadIcon fontSize="large" />
          <Typography variant="body1">
            Upload {type} type .kml file
          </Typography>
        </>
      )}
      {file && (<label htmlFor="fileInput " className='border px-2 shadow-md'>
        <Typography variant="body2" component="span">
          Change file
        </Typography>
      </label>)}
    </div>
  )
}
export default FileInput