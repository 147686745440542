import React, { useState } from 'react'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
const BaseButton = ({ setter }) => {
    const [visible, setVisible] = useState(false)
 
   

   
    const getButtonTrigger = () => {
        return (
            <div className='base-button-trigger p-1 ' onClick={()=>{setVisible(!visible)}} 
            >


               
                <div className='baseLayerButton h-[100%] w-[100%] flex justify-center items-end'>

                 
                    <p className='bg-[#494949] bg-opacity-20 text-[14px] font-bold'>Base Map</p>
                </div>


            </div>
        )
    }
    const handelClick = (e) => {
        setVisible(false)
        setter(e.target.parentElement.lastChild.innerHTML)
    }
    const getButton = (title,) => {

        return (
            <div>
                <div className={'flex flex-col shadow-md '} >

                    <div onClick={handelClick} 
                    className='base-button bg-[url("https://i0.wp.com/tiller.com.bd/wp-content/uploads/2021/05/cropped-tiller_logo-3.png?resize=77%2C74&ssl=1")] h-[52px] w-[52px]  '>

                    </div>
                    <p className='text-[12px]'>{title}</p>
                </div>
            </div>
        )
    }

    return (
        <div className='base-button-container bottom-16'>
            {getButtonTrigger()}
            <div className='base-button-panel' style={{ display: visible ? 'flex' : 'none' }}>
                {getButton("Google")}
                {getButton("Satellite")}
                {getButton("OSM")}
            </div>

        </div>
    )
}

export default BaseButton