import React, { useEffect, useState, useRef } from 'react';
import 'ol/ol.css';
import OlMap from 'ol/Map';
import OlSourceOSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import TileWMS from 'ol/source/TileWMS';
import LayerGroup from 'ol/layer/Group';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import SatelliteMap from 'ol/source/XYZ';
import { Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from "react-router";
import XYZ from 'ol/source/XYZ';
import BaseButton from './BaseButton';
import '../index.css'
import Header from '../../../shared/Header';
import Footer from '../../../shared/Footer';
import Legend from './Legend';
import CustomizedList from '../../../shared/CollapseMenu';
import '../../../../css/analyzermap.css'
import { ClearOutlined, MenuOutlined } from '@mui/icons-material';
import { GetContext } from '../../../shared/Context';
import { allLayers, getTitleLayers } from '../../../shared/LayerListHelper';
import { isLoggedIn } from '../../../../utils/util';

let selectedLayers = [];


const PublicMap = () => {
    const myContext = GetContext();
    const [selectedBase, setSelectedBase] = useState();
    const [map, setMap] = useState();
    const [baseSelectedLayer, setSelectedBaseLayer] = useState();
    const [selection, setSelection] = useState()
    const [selectedLayersList, setSelectedLayersList] = useState()
    const [clearOpen, setClearOpen] = useState(true)
    const navigate = useNavigate();
    const mapRef = useRef();


    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/')
        }
    }, [])
    mapRef.current = map;

    /* *********** Main Map view ********** */
    const viewProps = new OlView({
        center: [90.45607886403209, 23.10099061856948],
        zoom: 7.4,
        projection: 'EPSG:4326',
    });

    let Others;
    useEffect(() => {
        // console.log(selectedLayers);
    }, [selection])

    /* *********** ALL Base map Src ********** */
    const GoogleMapSrc = new XYZ({
        url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}'
    });
    const GoogleMap = new TileLayer({
        title: 'google',
        type: 'base',
        source: GoogleMapSrc
    });
    const USGSMapSrc = new XYZ({
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
    });
    const SatelliteMappSrc = new SatelliteMap({
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        maxZoom: 23,
    });
    const sate = new TileLayer({
        title: 'Sate',
        type: 'base',
        source: SatelliteMappSrc,
        visible: false
    });
    //create a div

    const OsmBaseSrc = new OlSourceOSM();

    /***********Layer Group ************/
    Others = new LayerGroup({
        fold: 'close',
        name: 'Others',
        layers: allLayers,
    });



    /* *********** Main Map ********** */
    useEffect(() => {
        const baseLayer = GoogleMap;
        const olmap = new OlMap({
            target: mapRef.current,
            layers: [
                GoogleMap,
                sate,
                Others,
            ],
            view: viewProps,
        });
        setMap(olmap);

        setSelectedBaseLayer(baseLayer);
    }, []);


    window.onload = function () {
        window.location = "/allprojects";
    }


    useEffect(() => {
        const layerType = myContext.projectData?.type
        const layerId = myContext.projectData?.id
        const newCenter = [myContext.projectData?.centerlon
            , myContext.projectData?.centerlat]
        /********Create Layer from selected project and show on map******* */
        const analizerLayer = new TileLayer({
            source: new TileWMS({
                url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
                params: { LAYERS: `dpp_projects_${layerType}`, CQL_FILTER: `[id IN ('${layerId}')]`, TILED: true },
                serverType: 'geoserver',
                crossOrigin: 'anonymous',
            }),
        });
        const extent = myContext.projectData?.bbox.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' }).join(',').split(',')
        if (map != null || map != undefined) {
            map.addLayer(analizerLayer);
            if (layerType !== 'point') {
                map.getView().fit(extent, { padding: [400, 400, 400, 400], constrainResolution: false });
            } else {
                viewProps.setCenter(newCenter);
                viewProps.setZoom(19);
            }
        }

    }, [myContext.projectData.id, map]);


    const getSelectedLayer = () => {
        /************Get Selected/Checked layers for analysis************ */
        if (map != null || map != undefined) {
            selectedLayers = [];
            map.getLayers().getArray().slice().forEach(layer => {
                if (layer.get('name') === 'Others') {
                    layer.getLayers().getArray().slice().forEach(layers => {
                        if (layers.get('visible') !== false) {
                            selectedLayers.push(layers.get('name'))
                        }
                    })
                }
            });
        }
        myContext.setSelectedLayer(selectedLayers);
    };

    /***********Analyzer Button Action********** */
    const analyzerButtonAction = () => {
        getSelectedLayer();
        if (selectedLayers.length > 0) {
            navigate('/analyzerresult');
        } else alert('Please Select a layer')
    }

    /********Formatting Seleted Base Map Src ********* */
    useEffect(() => {
        const baseMap = selectedBase == 'Google' ? GoogleMapSrc : selectedBase == 'Satellite' ? SatelliteMappSrc : selectedBase == 'OSM' ? OsmBaseSrc : GoogleMap;
        if (map != null) {
            baseSelectedLayer.setSource(
                baseMap
            );
        }
    }, [selectedBase]);


    /* Remove Extra Rendered Map */
    const showMap = () => {
        if (map != null || map != undefined) {
            const mapElement = document.getElementById('map');
            if (mapElement.childElementCount) {
                while (mapElement.childElementCount > 1) {
                    mapElement.removeChild(mapElement.firstElementChild);
                }
            }
        }
    }
    useEffect(() => {
        showMap();
    }, [map]);

    

    /************Function for finding layer from selected layer list text*************** */

    const [legendProp, setLegendProp] = useState([])

    useEffect(() => {
        const layers = []
        var no_layers = Others.getLayers().get('length')
        if (selection && selection != undefined) {
            const getTitle = selection.element.title;
            getTitleLayers(getTitle).setVisible(selection.visibility);
            for (var i = 0; i < no_layers; i++) {
                if (Others.getLayers().item(i).get('visible') !== false) {
                    layers.push({ title: Others.getLayers().item(i).get('title'), name: Others.getLayers().item(i).get('name') })
                }
            }
        }
        else {
            for (var i = 0; i < no_layers; i++) {
                if (Others.getLayers().item(i).get('visible') === true) {
                    Others.getLayers().item(i).setVisible(false)
                }
            }
        }

    }, [selection]);

    return (
        <div className='h-100vh flex flex-col'>
            <Header addStyle=' sticky top-0 z-[99] ' />

            <div className='analyzer-container '>
                <div className='layers-container'>
                    <CustomizedList
                        selectedLayers={selectedLayersList}
                        setSelectedLayers={setSelectedLayersList}
                        setter={setLegendProp} layerSelector={setSelection}
                        map={map} />
                </div>
                <div className='layer-toggle-container'>
                    {!clearOpen && <MenuOutlined className='layer-toggle' onClick={() => {
                        setClearOpen(true)
                        let layerContainer = document.getElementsByClassName('layer-container')
                        layerContainer[0].style.display = "block"
                        let legendContainer = document.getElementsByClassName('legend-container')
                        legendContainer[0].style.display = "block"
                    }} />}
                    {clearOpen && <ClearOutlined className='layer-toggle' onClick={e => {
                        setClearOpen(false)
                        let layerContainer = document.getElementsByClassName('layer-container')
                        layerContainer[0].style.display = "none"
                        let legendContainer = document.getElementsByClassName('legend-container')
                        legendContainer[0].style.display = "none"
                    }} />}
                </div>


                <div className='map-container' ref={mapRef} id="map" style={{ width: '100%', height: '90vh', }} >
                </div>

                <div className='legend-container'>
                    <Legend layers={legendProp} />
                </div>
            </div>
            <div className='fixed bottom-16 z-[1] text-center w-full'>
                <Button variant="contained" color="success" onClick={analyzerButtonAction}>Analyze</Button>
            </div>
            <BaseButton setter={setSelectedBase} />
            <Footer />

        </div>
    );
}

export default PublicMap;
