import React, { useEffect } from 'react'
import { useContext, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"



const TableBuilder = ({ keyField = '', data = [], columns = [], id }) => {
    
    return (<div className='mb-2'>
        <BootstrapTable
            id={id}
            classes='mb-0'
            hover
            bootstrap4
            keyField={keyField}
            data={data}
            columns={columns}
        />
    </div>

    )
}

export default TableBuilder