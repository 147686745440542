export const getMapTitle = (map_id) => {
    switch (map_id) {
        case 'waterbodies_bd':
            return 'WaterBody'
        case 'soil_moisture_bd':
            return 'SoilMoisture'
        case 'salinity_soil_bd':
            return 'SoilSalinity'
        case 'roads_line':
            return 'Roads'
        case 'river_bd':
            return 'Rivers'
        case 'hatbazar_bd':
            return 'Hatbazar'
        case 'climatestress_area_bd':
            return 'Climate Stress Areas'
        case 'climatestresspoint_bd':
            return 'Climate Stress Point'
        case 'flood10YearsReturn_period':
            return 'Flood 10 Years Return Period'
        case 'flood25YearsReturn_period':
            return 'Flood 25 Years Return Period'
        case 'flood100YearsReturn_period':
            return 'Flood 100 Years Return Period';
        case 'landuse_bd':
            return 'Landuse';
        case 'ecological_critical_area':
            return 'Ecological Critical Area';
        case 'brickfield_bd':
            return 'BrickField';
        case 'detailareaplan_proposed':
            return 'DetailAreaPlan';
        case 'cyclone100YearsReturn_period':
            return 'Cyclone 100 Years Return Period';
        case 'cyclone25YearsReturn_period':
            return 'Cyclone 25 Years Return Period';
    }
};

export const getMapId = (map_id) => {
    switch (map_id) {
        case 'Flood10YearsReturnPeriod':
            return 'flood10YearsReturn_period'
        case 'Flood25YearsReturnPeriod':
            return 'flood25YearsReturn_period'
        case 'Flood100YearsReturnPeriod':
            return 'flood100YearsReturn_period'
        case 'WaterBody':
            return 'waterbodies_bd'
        case 'SoilMoisture':
            return 'soil_moisture_bd'
        case 'SoilSalinity':
            return 'salinity_soil_bd'
        case 'Roads':
            return 'roads_line'
        case 'Rivers':
            return 'river_bd'
        case 'Hatbazar':
            return 'hatbazar_bd'
        case 'DeltaHotspot':
            return 'delta_hotspot_zone'
        case 'ClimateStressArea':
            return 'climatestress_area_bd'
        case 'Climate Stress Point':
            return 'climatestresspoint_bd';
        case 'Landuse':
            return 'landuse_bd';
        case 'Structure':
            return 'structure_bd';
        case 'EcologicalCriticalArea':
            return 'ecological_critical_area';
        case 'BrickField':
            return 'brickfield_bd';
        case 'DetailAreaPlan':
            return 'detailareaplan_proposed';
        case 'Cyclone100YearsReturnPeriod':
            return 'cyclone100YearsReturn_period'
        case 'Cyclone25YearsReturnPeriod':
            return 'cyclone25YearsReturn_period'
    }
}

