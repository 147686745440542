import './index.css'
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Header from "../shared/Header";
import Footer from '../shared/Footer'
import axios from 'axios';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import UnauthorizedPage from '../DefaultPage/UnathorizedPage';
import { capitalizeOnlyFirst, isLoggedIn } from '../../utils/util'
import { Button, CircularProgress } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate } from 'react-router';
import ActionModal from './ActionModal';
import Pagination from '../shared/Pagination';

const COLORS = { active: '#1b998b', inactive: '#f46036', pending: '#ffbc42' }

const AdminTable = () => {
  const [statusFilter, setStatusFilter] = useState("all");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const base_url = process.env.REACT_APP_BACKEND_IP

  const [data, setData] = useState([])
  const [paginatedData, setPaginatedData] = useState([])
  const [allData, setAllData] = useState([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(<></>)
  const [updateStatus, setUpdateStatus] = useState(false)
  const userData = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()

  useEffect(() => {
    if (!userData) {
      navigate('/')
    }
  }, [])

  const getFiltered = (fetchedData) => {

    return {
      all:
        fetchedData.map((e, index) => {
          return { ...e, index: index + 1 }
        }),
      pending: fetchedData.filter(el => {
        return el.status === 'pending'
      }).map((e, index) => {
        return { ...e, index: index + 1 }
      }),
      active: fetchedData.filter(el => {
        return el.status === 'active'
      }).map((e, index) => {
        return { ...e, index: index + 1 }
      }),
      inactive: fetchedData.filter(el => {
        return el.status === 'inactive'
      }).map((e, index) => {
        return { ...e, index: index + 1 }
      })
    }
  }


  useEffect(() => {
    setLoading(true)
    axios.get(`${base_url}/admin/getusers`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(res => {
        const filteredData = res.data.users.length ? getFiltered(res.data.users) : []

        setAllData(filteredData)

        setLoading(false)
      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
        setError(<div className='text-red-500 font-semibold'><WarningAmberIcon /> {e.message || e.code}</div>)
        setLoading(false)
      })

  }, [updateStatus])

  useEffect(() => {

    setData(allData[statusFilter])

  }, [allData, statusFilter])



  const handleAdminAction = ({ id, email }, action) => {
    if (action === 'reset_pass') {

      return
    }

    const url = `${base_url}/admin/userstatus?action=${action}`
    axios.put(url, { email, id }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(res => {
        setTimeout(() => {
          setUpdateStatus(!updateStatus)
          setShowModal(false);
        }, 300);
      })
      .catch(e => {
        if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
        console.log(e);
      })

  }





  const handleActionClick = (row) => {
    setSelectedUser(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleAddUser = () => {
    navigate('/adduser')
  }

  const sortFunction = (order) => {
    if (!order) return <span>&nbsp;&nbsp;<i className="fas fa-sort"></i></span>;
    else if (order === 'asc') return <span>&nbsp;&nbsp;<NorthIcon fontSize='xs' /></span>;
    else if (order === 'desc') return <span>&nbsp;&nbsp;<SouthIcon fontSize='xs' /></span>;
    return null;
  }

  const columns = [
    { dataField: "index", text: "ID", headerStyle: { width: '8%' }, /* sort: statusFilter === 'all' ? true : false, sortCaret: sortFunction  */ },
    { dataField: "user_name", text: "Name" },
    {
      dataField: "department", text: "Department", formatter: (value) => {

        if (value) {
          return (<>
            <p>{value.split("-")[0]}</p>
            <p>{value.split("-")[1]}</p>
          </>)
        }
        return 'N/A'

      }
    },
    {
      dataField: "status", text: "Active Status", headerStyle: { width: '12%', },
      formatter: (value) => {

        if (value) {

          return <p style={{ color: COLORS[value] }} className='font-semibold'>{capitalizeOnlyFirst(value)}</p>
        }
        return 'N/A'

      }
    },
    {
      dataField: "action", text: "Action", headerStyle: { width: '12%' }, formatter: (cellContent, row) => (
        <button className="btn btn-primary mx-auto my-auto"
          onClick={() => handleActionClick(row)}>Action</button>
      )
      /* */
    },
  ];


  const handleStatusFilterChange = (status) => {

    setStatusFilter(status);

  };




  return (
    <div className="w-full h-[100%]  ">
      <Header addStyle=' sticky top-0' />

      {userData?.userType !== 0 ? <UnauthorizedPage /> :
        (<div className='h-full  '>

          <div className=' mt-10 mb-20  w-full lg:w-[65%] mx-auto px-2'>
            <div className='flex justify-between  top-24  mx-auto items-center'>
              <div className="  tabs_wrap mt-2">
                <ul>
                  <li
                    className={`${statusFilter === "all"
                      ? "bg-blue-500 text-white active"
                      : "bg-gray-200 text-gray-700"
                      } px-4 py-2 rounded-md`}
                    onClick={() => handleStatusFilterChange("all")}
                  >
                    All
                  </li>
                  <li
                    className={`${statusFilter === "pending"
                      ? "bg-blue-500 text-white active "
                      : "bg-gray-200 text-gray-700"
                      } px-4 py-2 rounded-md`}
                    onClick={() => handleStatusFilterChange("pending")}
                  >
                    Pending
                  </li>
                  <li
                    className={`${statusFilter === "active"
                      ? "bg-blue-500 text-white active"
                      : "bg-gray-200 text-gray-700"
                      } px-4 py-2 rounded-md`}
                    onClick={() => handleStatusFilterChange("active")}
                  >
                    Active
                  </li>
                  <li
                    className={`${statusFilter === "inactive"
                      ? "bg-blue-500 text-white active"
                      : "bg-gray-200 text-gray-700"
                      } px-4 py-2 rounded-md`}
                    onClick={() => handleStatusFilterChange("inactive")}
                  >
                    Inactive
                  </li>
                </ul>
              </div>
              <div className='' style={{ borderRadius: '6px' }}>
                <button
                  onClick={handleAddUser}
                  className='bg-[#1976D2ef] px-2 py-2 my-auto rounded-lg text-white'>
                  <PersonAddAltIcon sx={{ mr: 2 }} />Add new user</button>
              </div>
            </div>

            <div className=' mx-auto mt-2 py-2'>
              {loading && <CircularProgress />}
              {!loading && data?.length ? (<BootstrapTable headerStyle={{ background: '#00ff00' }}
                keyField="id"
                data={paginatedData}
                columns={columns} />) : <>No Data Found</>}
            </div>


            {/* Pagiantion Panel */}
            <Pagination
              data={data}
              setPaginatedData={setPaginatedData} />
          </div>
        </div>)}



      <ActionModal isOpen={showModal} user={selectedUser}
        colors={COLORS}
        action={handleAdminAction} setIsOpen={handleModalClose} />

      <Footer />
    </div>
  );
};

export default AdminTable;


