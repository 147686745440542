import { useEffect, useState } from 'react';
import OlMap from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import XYZ from 'ol/source/XYZ';
import Footer from './Footer'
import Header from './Header'
import axios from 'axios';
import { GetContext } from './Context';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import TileWMS from 'ol/source/TileWMS';

import ModalContainer from './ModalContainer';

const GoogleMapSrc = new XYZ({
    url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
    //  url: 'https://khms1.googleapis.com/kh?v=152&hl=es-ES&x={x}&y={y}&z={z}'
});
const GoogleMap = new TileLayer({
    title: 'google',
    type: 'base',
    source: GoogleMapSrc
    //    visible: false
});
const ModalContent = (data) => {
    return (<div className='text-white bg-black'>

        Project Name : {data.dppdetails?.project_name ? data.dppdetails?.project_name : 'N/A'}
    </div>)
}
const PPSLanding = () => {
    const [data, setData] = useState({})
    const [details, setDetails] = useState(null)
    const [map, setMap] = useState()
    const base = process.env.REACT_APP_BACKEND_IP_ASSETS
    const [showBasicInfo, setShowBasicInfo] = useState(false)
    const navigate = useNavigate()


    const viewProps = new OlView({
        center: [90.45607886403209, 23.10099061856948],
        zoom: 7.4,
        projection: 'EPSG:4326',
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const showMap = (map) => {
        if (map != null || map != undefined) {
            const mapElement = document.getElementById('map');
            if (mapElement.childElementCount) {
                while (mapElement.childElementCount > 1) {
                    mapElement.removeChild(mapElement.firstElementChild);
                }
            }
        }
    }
    useEffect(() => {

        const olmap = new OlMap({

            layers: [
                GoogleMap,
            ],
            view: viewProps,
        });
        olmap.setTarget('map')
        showMap(olmap);
        setMap(olmap)
    }, []);



    const handleBasicAnalysis = (e) => {
        /* SHOW MODAL WITH INFO */
        navigate('/ppsanalysis')
        // setShowBasicInfo(true)

    }

    const getdata = (token) => {
        const url = `${base}/pps/dpp/details`


        axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {

                setData(res.data)
                const { pps_id, project_name, district, upazila, agency_name, sector, sub_sector, project_start_date, project_end_date } = res.data.dppdetails
                setDetails({ pps_id, project_name, district, upazila, agency_name, sector, sub_sector, project_start_date, project_end_date })


            })
            .catch(e => { 
  if (e.response.data == "jwt expired") {
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          localStorage.removeItem("password")
          window.location.href = process.env.REACT_APP_FE_HOME;
        }
                console.log(e);
            })

    }



    useEffect(() => {
        const url = `${base}/pps/access-token/${id}`

        axios.get(url)
            .then(res => {
                const token = res.data.token
                localStorage.setItem('token', JSON.stringify(token || 'noTOKEN'))
                if (res.status == 200) {
                    getdata(token)
                }
            })
            .catch(e => console.log(e))

    }, [])


    useEffect(() => {



        // const newCenter = [data.centerlon, data.centerlat];



        /********Create Layer from selected project and show on map******* */
        if (Object.keys(data).length && map) {
            const layerType = data.plis_dpp_type;
            const layerId = data.dppdetails.pps_uuid;

            if (data?.plis_dpp_type) {
                const analizerLayer = new TileLayer({
                    source: new TileWMS({
                        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,
                        params: { LAYERS: `dpp_projects_${layerType}`, CQL_FILTER: `[pps_uuid IN ('${layerId}')]`, TILED: true },
                        serverType: 'geoserver',
                        crossOrigin: 'anonymous',
                    }),
                });

                const extent = data.bbox?.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' }).join(',').split(',');

                if (map != null || map != undefined) {
                    map.addLayer(analizerLayer);
                    if (layerType !== 'point' && extent) {
                        map.getView().fit(extent, { maxZoom: 16, constrainResolution: false });

                    } else {
                        // viewProps.setCenter(newCenter);
                        viewProps.setZoom(19);
                    }
                }
            }
            else {

                const upazilaLayer = new TileLayer({
                    source: new TileWMS({
                        url: `http://${process.env.REACT_APP_GEOSERVER_IP}/geoserver/accnldp/wms`,

                        params: {
                            LAYERS: `boundary_upazila`,
                            CQL_FILTER: [`upazila IN ('${data.dppdetails?.upazila?.split(', ').join("', '")}') AND dist_name IN ('${data.dppdetails.district?.split(', ').map(el => el.toUpperCase()).join("', '")}')`],

                            // CQL_FILTER: [`upazila IN ('Kumarkhali', 'Shalikha', 'Sreepur') AND dist_name IN ('KUSHTIA', 'MAGURA', 'MAGURA')`],
                            TILED: true
                        }, //'Batiaghata, Gaurnadi'


                        //  params: { LAYERS: `boundary_upazila`, CQL_FILTER: `[upazila IN ('Batiaghata', 'Gaurnadi')]`, TILED: true },
                        serverType: 'geoserver',
                        crossOrigin: 'anonymous',
                    }),
                });

                // map.addLayer(upazilaLayer) //'Khulna','Dhaka','Bagerhat'

                const extent = data.bbox?.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' }).join(',').split(',');

                if (map != null || map != undefined) {
                    map.addLayer(upazilaLayer);
                    if (layerType !== 'point' && extent) {
                        map.getView().fit(extent, { maxZoom: 16, constrainResolution: false });

                    } else {
                        // viewProps.setCenter(newCenter);
                        viewProps.setZoom(19);
                    }
                }
            }
        }

        // Get Basic Project data from selected project and store it for showing on analysis result page
        /*  if (!data) {
             fetch(`${base_url}/analysis/basicinfo/dpp_projects_${layerType}/${layerId}`)
                 .then((res) => res.json())
                 .then((json) => {
                     myContext.setBasicProjectData(json[0])
 
                 });
         } */
    }, [data, map])

    return (
        <div style={{ width: '100%' }} className=''>
            <Header />
            <div className='my-24 w-[70%] mx-auto'>
                <div className='flex h-[100%] w-[100%] justify-center items-center gap-4 px-12'>
                    <div style={{ width: '100%', height: '50vh' }} className=' relative border-[#13183B] border-b-2 border-2 rounded-xl'>
                        <div className=' text-black py-[.75rem] text-lg rounded-t-xl shadow-md'>
                            <h3 className='font-bold'>Project Details</h3>
                        </div>
                        <div className=' w-full   '>
                            {details ?
                                (<div className='text-left py-4 px-2'>

                                    {Object.entries(details).map(([key, value], index) => {
                                        return (value ? <p className='text-sm '><span className='font-semibold '>{`${key.split('_').map(el => `${el[0].toUpperCase()}${el.slice(1)}`).join(" ")}`}</span> : {`${value}`}</p> : <></>)
                                    })}
                                </div>)
                                : <p>NO INFO</p>
                            }
                        </div>

                        <div style={{ transform: 'translate(-50%, 0)' }} className=' w-[100%] left-[50%] absolute bottom-1  mt-2  rounded-xl flex justify-around items-center gap-4  h-12'>
                            {data?.plis_dpp_type ? (<Link to='/map'
                                className=' px-4 rounded-md text-black border-2
                             border-white hover:cursor-pointer hover:bg-[#afaaaa]'>SPATIAL ANALYSIS</Link>) :
                                (<div className='flex items-start'>
                                    <div className=''>
                                        <button className=' px-4 rounded-md
                                         text-[#ffffff] border-2 border-white hover:cursor-pointer bg-[#e40202] hover:bg-[#afaaaa]'>SPATIAL ANALYSIS</button>
                                        <p className='text-red-500 text-xs leading-[.5rem]'>*No Spatial File Found</p>
                                    </div>
                                    <div className=''>
                                        <button
                                            disabled={!(details)}
                                            onClick={handleBasicAnalysis}
                                            className={`px-4 rounded-md
                                         text-white border-2 border-white r  bg-[green] ${data.dppdetails ? 'hover:cursor-pointer ' : 'cursor-not-allowed '} hover:bg-[#aaafaa]`}>BASIC ANALYSIS</button>
                                        {!(data.dppdetails) && <p className='text-red-500 text-xs leading-[.5rem]'>*No Data Found</p>}

                                    </div> </div>)}

                            {/*<button className='bg-[#f3f3f3] px-4 rounded-md hover:cursor-pointer hover:bg-[#afaaaa]'>CCM CHECK</button> */}
                        </div>
                    </div>
                    <div className='overflow-hidden border-[#13183B] border-b-2 border-2 rounded-xl' id="map" style={{ width: '100%', height: '50vh', }} >
                    </div>
                </div>


            </div>
            {showBasicInfo && < ModalContainer onClose={() => setShowBasicInfo(false)}
                children={<ModalContent />} data={data} />}
            <Footer />
        </div>
    )
}

export default PPSLanding