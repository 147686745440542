import React, { useState } from 'react'
import { capitalizeOnlyFirst } from '../../utils/util'
import { Button } from '@mui/material'
import ChangePasswordModal from '../shared/ChangePasswordModal'
import noImage from '../../assets/images/user.png'

const ActionBtn = ({ text, bg, user, actionType, handler }) => {

    return (<button
        className=" text-white px-4 py-2 rounded-md" style={{
            display: (actionType == 'inactive'
                && user.user_type == 'Admin') ? 'none' : 'block'
            , backgroundColor: bg
        }}
        onClick={() => { handler && handler(user, actionType) }} >
        {text.toUpperCase()}
    </button>)
}


const ActionModal = ({ isOpen, user, action, setIsOpen, colors }) => {
    const [passModalOpen, setPassModalOpen] = useState(false)
    const [data, setData] = useState()
    const base_url = process.env.REACT_APP_BACKEND_IP
    const base_assets = process.env.REACT_APP_BACKEND_IP_ASSETS

    const handleImageNotFound = () => {
        const noImageFound = document.getElementById('profileImg')
        noImageFound.src = noImage
    }


    return (
        isOpen ? (<div className="fixed inset-0 bg-gray-900 
        bg-opacity-50 flex items-center justify-center ">
            <div className="bg-white rounded-lg p-4 w-[40%] mx-auto relative">
                <div className="text-gray-800 font-bold text-lg mb-4">
                    User Details
                </div>
                <div className='text-red-300 hover:text-red-500 text-center my-auto bg-gray-100 px-2 rounded-xl leading-8 absolute text-xl hover:text-2xl hover:-translate-1 cursor-pointer t top-2 right-4' onClick={() => { setIsOpen(false) }}  >
                    x
                </div>
                <hr />
                <div className='flex items-center gap-2'>
                    <img
                        onError={handleImageNotFound}
                        id="profileImg"
                        src={`${base_assets}/assets/profileimg/${user.img}`}
                        className="w-[20%] border p-1 bg-opacity-100 rounded-xl "
                    />
                    <div className=' w-[80%]  p-3 mx-auto text-start'>
                        <div className="text-gray-800 mb-4">
                            {user.user_name ? (<><span className='font-semibold mr-1'>Name:</span>  {user.user_name}</>) : <></>}
                        </div>
                        <div className="text-gray-800 mb-4">
                            {user.department ? (<><span className='font-semibold mr-1'>Department:</span> {user.department}</>) : ""}
                        </div>
                        <div className="text-gray-800 mb-4">
                            {user.email ? (<> <span className='font-semibold mr-1'>Email:</span> {user.email}</>) : <></>}
                        </div>
                        <div className="text-gray-800 mb-4">
                            {user.status ? (<><span className='font-semibold mr-1'>Status:</span> {capitalizeOnlyFirst(user.status)}</>) : <></>}
                        </div>

                    </div>

                </div>

                <div className="flex space-x-2">
                    {user.status == 'pending' ?
                        (<>
                            <ActionBtn text='Activate' bg={colors.active} user={user} actionType={'active'} handler={action} />
                        </>) :
                        user.status == 'active' ?
                            (<>
                                <ActionBtn text='Deactivate' bg={colors.inactive} user={user} actionType={'inactive'} handler={action} />
                            </>) :
                            (<>
                                <ActionBtn text='Re-activate' bg={colors.active} user={user} actionType={'active'} handler={action} />
                            </>)}
                    <ActionBtn text='Reset Password' bg={'#f4a261'} user={user} actionType={'reset_pass'} handler={() => {
                        setData(user)
                        setPassModalOpen(true)
                    }} />
                </div>
                <div className="flex justify-end">
                    <Button
                        variant='contained'
                        sx={{ p: 1, borderRadius: '40px', backgroundColor: '#ABABB0', ":hover": { backgroundColor: '#ABABB0' } }}

                        onClick={() => { setIsOpen(false) }}
                    >
                        Close
                    </Button>
                </div>
                {passModalOpen && <ChangePasswordModal data={data} onClose={() => { setPassModalOpen(false) }} />}
            </div>
        </div>) : <></>
    )
}

export default ActionModal