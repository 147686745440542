import React from 'react';

export default function UnauthorizedPage() {
  return (
    <div className="h-full text-center  text-yellow-600">
      <div className='my-[25%] translate-y-[25%] '>
        <img src="https://www.example.com/images/unauthorized.jpg" alt="Unauthorized" />
        <h1 className="text-3xl font-medium mt-4">401 Unauthorized</h1>
        <p className="mt-2">You are not authorized to access this page.</p>
      </div>

    </div>
  );
}
