import { Notes } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import {
    FormControl, FormControlLabel,
    FormLabel, InputAdornment,
    Radio, RadioGroup, TextField
} from '@mui/material';
import { GetContext } from '../../../shared/Context';







const CCMCheckList = ({ data, name, dataFor }) => {

    const myContext = GetContext()

    const [selectedValue, setSelectedValue] = useState({});
    const [comment, setComment] = useState({});
    const [answers, setAnswers] = useState({})

    const handleRadioChange = (event) => {
        setSelectedValue({ ...selectedValue, [event.target.name]: event.target.value });
        setAnswers({ ...answers, [event.target.name]: { ...answers[event.target.name], ans: event.target.value } })
        myContext.setCcmq({ ...myContext.ccmq, [dataFor]: { ...myContext.ccmq[dataFor], [event.target.name]: { ...answers[event.target.name], ans: event.target.value } } })
    };

    const handleComment = (event) => {
        setComment({ ...comment, [event.target.name]: event.target.value });
        setAnswers({ ...answers, [event.target.name]: { ...answers[event.target.name], comment: event.target.value } })
        myContext.setCcmq({ ...myContext.ccmq, [dataFor]: { ...myContext.ccmq[dataFor], [event.target.name]: { ...answers[event.target.name], comment: event.target.value } } })
    }

    useEffect(() => {

    }, [answers])
    return (<div>
        {data && data.length ? (<div className=' py-1 mb-1   mx-auto'>
            <h4 className='bg-slate-600 py-1 text-white font-semibold '>Climate Check Method Questionaries - {name} {/* {data[0].layer_name && data[0].layer_name.split("_").map(el => capitalizeFirstLetter(el)).join(" ")} */}</h4>
            <div className='text-left mt-2'>
                {data.map((el, index) => {
  
                    return (
                        <div key={`ccm-${el.id}-${el.ccm_q_number}`} className=' mb-2 bg-[#a7a2a279] p-2 rounded-md'>
                            <p className='' >প্রশ্ন -{el.ccm_q_number || index + 1}:{el.ccm_q_bn}</p>

                            {el.check_box ? (<FormControl>
                                <FormLabel >উত্তর</FormLabel>
                                <RadioGroup name={el.ccm_q_number || el.id} className='flex flex-row' value={selectedValue[el.ccm_q_number || el.id]} onChange={handleRadioChange}>
                                    <FormControlLabel value="হ্যাঁ" control={<Radio />} label="হ্যাঁ" />
                                    <FormControlLabel value="না" control={<Radio />} label="না" />
                                    <FormControlLabel value="প্রযোজ্য নয়" control={<Radio />} label="প্রযোজ্য নয়" />
                                </RadioGroup>
                            </FormControl>) : <div className='mb-2' />}
                            <div className='text-center mt-4 mb-4'>
                                <TextField
                                    size='small'
                                    multiline
                                    name={el.ccm_q_number || el.id}
                                    className='w-[98%]'
                                    label="মন্তব্য"
                                    id={`Q-${index}`}
                                    value={comment[el.ccm_q_number || el.id]}
                                    onChange={handleComment}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Notes />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>) : (<>
        </>)}
    </div>
    )
}

export default CCMCheckList