import React, { useEffect } from 'react';
import { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import axios from 'axios';
import { Button, InputAdornment } from '@mui/material';
import { cellDataFormatter } from "../../../shared/TableCellHelper";
import TextField from '@mui/material/TextField';
import { Notes } from '@mui/icons-material';
import PublicMap from './AnalizerResultMap';
import TableBuilder from './PrintComponent/TableBuilder';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import PrintComponent from './PrintComponent/PrintComponent';
import { GetContext } from '../../../shared/Context';
import CCMCheckList from './CCMCheckList';
import { getMapId } from '../../../shared/LayerSwitchCaseHelper';
import { useNavigate } from 'react-router-dom';



const separateCaps = (text) => {
  return text.match(/[A-Z][a-z]+|[0-9]+/g).join(" ") || text
}

var maps = {}


export default function AnalysisResult() {
  const myContext = GetContext()
  const selectedLayerList = myContext?.selectedLayer;
  const layerType = myContext?.projectData?.type;
  const layerId = myContext?.projectData?.id;
  const projectData = myContext?.projectData;
  const basicData = myContext?.basicProjectData;
  const [tableData, setTableData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [statusAction, setStatusAction] = useState();
  const [comment, setComment] = useState();
  const base_url = process.env.REACT_APP_BACKEND_IP
  const navigate =useNavigate()
  const userData = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (!userData) {
      navigate('/')
    }
  }, [])
  const setMaps = (map) => {
    maps = { ...maps, ...map }
    myContext.setMaps(maps)
  }
  const capitalizeFirstLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  }
  const columnBuilder = list => {
    const clmn = []
    list.map(el => {

      if (el !== 'closepointb' && el !== 'closepointa') {
        clmn.push({
          dataField: el,
          text: el.split('_').map(e => capitalizeFirstLetter(e)).join(" "),
          formatter: cellDataFormatter,
          sort: true
        })
      }
    })
    return clmn
  }

  /* eslint-disable */
  useEffect(() => {
    var url = `${process.env.REACT_APP_BACKEND_IP}/analysis/result/dpp_projects_${layerType}/${layerId}/layerlist?`

    selectedLayerList?.map((item) => {
      url = url + `${item.substring(0, item.indexOf("_"))}= true&`;
      return 0;
    })

    axios(url.slice(0, -1), {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((json) => {
        const tempTableData = { general: [], dedicated: [] }
        json.data.map((dt, ind) => {
          if (Object.keys(dt).length > 1) {
            let temp = {}

            Object.entries(dt).map((el, index) => {
              if (el[0] === 'columnList') {
                temp.columns = columnBuilder(el[1])
              }
              else if (el[0] === 'law') {
                temp.law = el[1].map(e => e.law)
              }
              else if (el[0] === 'ccmq') {
                temp.ccmq = el[1]
              }
              else {
                temp.title = capitalizeFirstLetter(el[0].substring(0, el[0].indexOf("Result")))
                temp.data = el[1]
              }
              if (Object.keys(dt).length - 1 === index) {
                if (!temp.columns) {
                  temp.columns = columnBuilder(Object.keys(temp.data[0]))
                }
                tempTableData.dedicated.push(temp)
              }
            })
          }
          else {
            tempTableData.general.push(dt)
          }
        })
        setTableData(tempTableData)
        setDataIsLoaded(true)

        myContext.setAnalysisResult(tempTableData.dedicated)

      });
  }, [])


  useEffect(() => {
    let temp = {}
    Object.entries(tableData).map(el => {
      temp[`${el[0]}`] = el[1].map(e => e.ccmq || e)

    })

    myContext.setCcmq(temp)
  }, [tableData])


  const handleSubmit = (e) => {

  }

  const handleComment = (event) => {
    setComment(event.target.value);
  }
  const handleGoBack = () => {
    navigate('/map')
  }


  return (
    <div className=" absolute py-[65px]  row text-center w-[100%]" style={{ display: 'flex', left: '0', right: '0', margin: '0 auto' }}>
      <div className="text-center"  >
        <div className="pt-10">
          <h1 className="h2 pb-6">Overall Analysis Result</h1>
          <div className=' w-[90%]' style={{ margin: '0 auto' }}>
            {!dataIsLoaded && <div className="data_loader"></div>}
            {dataIsLoaded ? tableData.length !== 0 ? (
              <div className='w-[85%] md:w-[75%] lg:w-[70%] mx-auto'>
                <div className=' flex justify-between items-center'>
                  {projectData !== null && !basicData ? 'Basic Data Not Found' : (
                    <div className=' content-start	pb-3 capitalize text-left analysisBasicInfo'>
                      <h3> <b>Project Name:</b> {projectData.project_name}</h3>
                      <h3> <b>Authority:</b> {projectData.authority}</h3>
                      <h3> <b>Division:</b> {basicData.division}</h3>
                      <h3> <b>District:</b> {basicData.district}</h3>
                      <h3> <b>Upazila:</b> {basicData.upazila}</h3>
                      <h3> <b>Mouza:</b> {(basicData.mouza) === null ? 'Not Found' : (basicData.mouza)}</h3>
                      <h6><b>Submission Date:</b> {projectData.submission_date}</h6>
                    </div>
                  )}
                  <Button onClick={handleGoBack} variant='contained'>
                    Back
                  </Button>
                </div>
                <div>
                  {tableData.general.map(el => {
                    
                    return (<CCMCheckList dataFor={Object.keys(el)[0]} data={el[Object.keys(el)[0]]} name={Object.keys(el)[0]} />)
                  })}

                  {tableData.dedicated.map((el, index) => {

                    if (el['ccmq_general_25.2'] || el['ccmq_division_wise'] || el['ccmq_general_25.3']) {


                    }

                    else {
                      let check = false;
                      if (myContext.projectData.id == 56 && myContext.projectData.type === 'polygon' && el.title == 'Landuse') {
                        check = true;
                      }


                      return (
                        <div key={el.title + index} className=' border-2 rounded-lg border-black px-4 pt-4 mt-4 mb-2'>

                          <h2 className='font-bold mb-1 bg-slate-400 p-1'>{el.title && separateCaps(el.title)}</h2>
                          <TableBuilder
                            id={`table_${getMapId(el.title)}`}
                            keyField={el.columns[0].dataField}
                            data={el.data}
                            columns={el.columns}
                            law={el.law}
                          />
                          {check && (<div>
                            <h2 className='font-bold mb-1 bg-slate-400 p-1'>{'Applicable Landuses'}</h2>
                            <TableBuilder
                              id={`table_${getMapId(el.title)}`}
                              law={el.law}
                            />
                          </div>)}
                          <div sx={{ width: '100%' }}>

                            <PublicMap setMaps={setMaps} map_id={getMapId(el.title)} />

                          </div>
                          <CCMCheckList dataFor={getMapId(el.title)} data={el.ccmq}
                            name={el.ccmq && el.ccmq.length && el.ccmq[0].layer_name && el.ccmq[0].layer_name.split("_").map(el => capitalizeFirstLetter(el)).join(" ")} />
                        </div>
                      )
                    }
                  })}
                </div>
                <div className='mt-4'>
                  <TextField
                    size='small'
                    multiline
                    className='w-1/2'
                    label="Comment"
                    id="fullWidth"
                    value={comment}
                    onChange={handleComment}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Notes />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className='z-[1] flex items-center justify-center gap-2 text-center w-full pt-2'>
                  <Button variant="contained" name="Approve" color="success"
                    style={{ margin: '2px', textTransform: 'none' }}
                    onClick={handleSubmit}>Submit</Button>
                  <PrintComponent maps={maps} />

                </div>
              </div>
            ) : <h6 >No Data Found</h6> : <h6 >Loading...</h6>}

          </div>

        </div>
      </div>
    </div>
  )
}
